import React, { useState } from "react";
import profile from "../assets/profile.png";
import { Divider } from "@mui/joy";
import { CgMail } from "react-icons/cg";
import { MdOutlinePhoneInTalk, MdLogout } from "react-icons/md";
import { IoHomeOutline } from "react-icons/io5";
import { LiaBirthdayCakeSolid } from "react-icons/lia";
import { CgProfile } from "react-icons/cg";
import { MdOutlineModeEdit } from "react-icons/md";
import { BsGenderFemale } from "react-icons/bs";
import { FaLocationDot } from "react-icons/fa6";
import { IoCamera } from "react-icons/io5";
import { GrGallery } from "react-icons/gr";
import useStore from "../store";
import { useNavigate } from "react-router-dom";

const UserProfile = () => {
  const { currentUser } = useStore((state) => ({
    currentUser: state.currentUser,
  }));
  const [editMode, setEditMode] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProfilePictureModalOpen, setIsProfilePictureModalOpen] =
    useState(false);
  const [userData, setUserData] = useState({
    email: "julianjuleslpardi@gmail.com",
    name: "Julian Jules L. Pardi",
    phone: "+639949484091",
    address: "San Isidro Rodriguez Rizal",
    age: "21",
    gender: "Male",
  });

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("userRegistered");
    localStorage.removeItem("access_token");
    window.location.href = "/login";
  };

  if (!currentUser) {
    return <div>No user data available</div>;
  }

  const handleEditClick = () => {
    setEditMode(!editMode);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSaveClick = () => {
    setEditMode(false);
    // Add logic to save changes if needed, such as updating a database
  };

  const handleCancelClick = () => {
    setEditMode(false);
    // Optionally, you could reset to original data if changes were not saved
  };

  const handleCameraClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsProfilePictureModalOpen(false);
  };

  const handleSeeProfilePicture = () => {
    setIsProfilePictureModalOpen(true);
  };

  const addressLocation = currentUser.addressLocation?.[0];

  const currentLocation = {
    street: addressLocation?.street || "No street available",
    barangay: addressLocation?.barangay?.name || "No barangay available",
    city: addressLocation?.city?.name || "No city available",
    province: addressLocation?.province?.name || "No province available",
    region: addressLocation?.region?.name || "No region available",
    latitude: addressLocation?.latitude || 0,
    longitude: addressLocation?.longitude || 0,
  };

  return (
    <div className="w-full">
      <div className="rounded-b-2xl p-16 drop-shadow-2xl bg-gradient-to-r from-blue-300 from-10% via-blue-400 via-30% to-blue-500 to-90%"></div>
      <div className="flex justify-center w-full -mt-16 relative">
        <img
          src={currentUser.profilePicture}
          alt="Profile"
          className="w-28 h-28 z-10 relative rounded-full object-cover"
        />
        <div className="absolute bottom-0 right-30 transform translate-x-8 translate-y-1 bg-gray-300 w-8 h-8 p-2 border-2 border-white rounded-full z-20 flex items-center justify-center">
          <button onClick={handleCameraClick}>
            <IoCamera size={16} />
          </button>
        </div>
      </div>

      <div className="text-center">
        <p className="font-bold text-xl pt-3">{currentUser.name}</p>
        <div className="flex justify-center gap-2 pt-3">
          <FaLocationDot className="self-center" size={13} />
          <div>
            <p className="text-xs">
              {currentLocation.city} | Joined {currentUser.dateJoined}
            </p>
          </div>
        </div>
      </div>

      <div className="px-6">
        <div className="flex justify-between pt-5">
          <p className="text-start font-semibold">Personal Information</p>
          {!editMode && (
            <button onClick={handleEditClick}>
              <MdOutlineModeEdit
                className="self-center text-slate-600"
                size={18}
              />
            </button>
          )}
        </div>
        <div className="rounded-lg bg-white mt-2 p-5">
          <div className="flex justify-between py-2">
            <div className="w-full flex gap-4 items-center">
              <div className="bg-gray-100 rounded-xl p-2">
                <CgProfile className="self-center text-slate-600" size={18} />
              </div>
              {editMode ? (
                <input
                  type="text"
                  name="name"
                  value={currentUser.name}
                  onChange={handleInputChange}
                  className="w-full text-sm"
                  style={{ border: "none", outline: "none" }}
                />
              ) : (
                <p className="text-sm self-center">{currentUser.name}</p>
              )}
            </div>
          </div>
          <Divider />

          <div className="flex justify-between py-2">
            <div className="w-full flex gap-4 items-center">
              <div className="bg-gray-100 rounded-xl p-2">
                <MdOutlinePhoneInTalk
                  className="self-center text-slate-600"
                  size={18}
                />
              </div>
              {editMode ? (
                <input
                  type="text"
                  name="phone"
                  value={currentUser.phone}
                  onChange={handleInputChange}
                  className="w-full text-sm"
                  style={{ border: "none", outline: "none" }}
                />
              ) : (
                <p className="text-sm self-center">{currentUser.phone}</p>
              )}
            </div>
          </div>
          <Divider />

          <div className="flex justify-between py-2">
            <div className="w-full flex gap-4 items-center">
              <div className="bg-gray-100 rounded-xl p-2">
                <IoHomeOutline
                  className="self-center text-slate-600"
                  size={18}
                />
              </div>
              {editMode ? (
                <input
                  type="text"
                  name="address"
                  value={userData.address}
                  onChange={handleInputChange}
                  className="w-full text-sm"
                  style={{ border: "none", outline: "none" }}
                />
              ) : (
                <p className="text-sm self-center">
                  {currentUser.addressLocation &&
                  currentUser.addressLocation.length > 0 ? (
                    <>
                      {currentUser.addressLocation[0].street ||
                        "Unknown Street"}
                      ,{" "}
                      {currentUser.addressLocation[0].barangay?.name ||
                        "Unknown Barangay"}
                    </>
                  ) : (
                    "Address not available"
                  )}
                </p>
              )}
            </div>
          </div>
          <Divider />

          <div className="flex justify-between py-2">
            <div className="w-full flex gap-4 items-center">
              <div className="bg-gray-100 rounded-xl p-2">
                <LiaBirthdayCakeSolid
                  className="self-center text-slate-600"
                  size={18}
                />
              </div>
              {editMode ? (
                <input
                  type="text"
                  name="age"
                  value={userData.age}
                  onChange={handleInputChange}
                  className="w-full text-sm"
                  style={{ border: "none", outline: "none" }}
                />
              ) : (
                <p className="text-sm self-center">{userData.age}</p>
              )}
            </div>
          </div>
          <Divider />

          <div className="flex justify-between py-2">
            <div className="w-full flex gap-4 items-center">
              <div className="bg-gray-100 rounded-xl p-2">
                <BsGenderFemale
                  className="self-center text-slate-600"
                  size={18}
                />
              </div>
              {editMode ? (
                <input
                  type="text"
                  name="gender"
                  value={userData.gender}
                  onChange={handleInputChange}
                  className="w-full text-sm"
                  style={{ border: "none", outline: "none" }}
                />
              ) : (
                <p className="text-sm self-center">{currentUser.gender}</p>
              )}
            </div>
          </div>

          {editMode && (
            <div className="flex justify-end gap-2 mt-4">
              <button
                onClick={handleCancelClick}
                className="px-4 py-2 text-sm bg-gray-300 rounded-md"
              >
                Cancel
              </button>
              <button
                onClick={handleSaveClick}
                className="px-4 py-2 text-sm bg-blue-500 text-white rounded-md"
              >
                Save
              </button>
            </div>
          )}
        </div>
      </div>

      <div
        onClick={handleLogout}
        className="m-6 p-2 bg-blue-500 text-white rounded-lg flex gap-2 items-center justify-center"
      >
        <MdLogout size={20} /> Log Out
      </div>

      {/* Camera Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <p className="mb-4 text-center">Choose an option:</p>
            <div className="flex flex-col gap-4">
              <button
                className="flex bg-blue-500 text-white py-2 px-5 rounded-md"
                onClick={handleSeeProfilePicture}
              >
                <div className="rounded-xl p-2">
                  <CgProfile className="self-center text-white" size={16} />
                </div>
                <p className="self-center">See Profile Picture</p>
              </button>
              <button
                className="flex bg-blue-500 text-white py-2 rounded-md"
                onClick={() => alert("Choose Profile Picture clicked")}
              >
                <div className="rounded-xl p-2">
                  <GrGallery className="self-center text-white" size={16} />
                </div>
                <p className="self-center">Choose Profile Picture</p>
              </button>
              <button
                className="bg-gray-300 py-2 rounded-md"
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Profile Picture Modal */}
      {isProfilePictureModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <img
              src={profile}
              alt="Profile"
              className="w-64 h-64 object-cover mx-auto"
            />
            <div className="flex justify-end mt-4">
              <button
                onClick={closeModal}
                className="px-4 py-2 text-sm bg-gray-300 rounded-md"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserProfile;
