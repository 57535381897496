import React, { useState } from "react";
import { IoArrowBack } from "react-icons/io5";
import { HiDotsHorizontal } from "react-icons/hi";
import { RiRobot2Fill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

const ChatHeader = ({ toggleChatList, isChatListOpen }) => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="flex">
      <div className="w-full flex justify-between bg-white dark:bg-primary-dark-color text-text dark:text-text_dark p-4 items-center cursor-pointer">
        <div>
          <div className="self-center" onClick={handleGoBack}>
            <IoArrowBack size={20} />
          </div>
        </div>
        <div className="flex gap-2 items-center">
          <div className="bg-gradient-to-r from-blue-300 to-blue-500 text-white p-2 rounded-full">
            <RiRobot2Fill size={20} />
          </div>
          <p className="text-lg font-semibold"> Respo Chat Bot</p>
        </div>
        <div onClick={toggleChatList}>
          <HiDotsHorizontal size={20} />
        </div>
      </div>
    </div>
  );
};

export default ChatHeader;
