import { Navigate } from "react-router-dom";
import useStore from "../store";
import UserNavigation from "./UserNavigation";
import EmergencyAlertPopup from "./EmergencyAlertPopup";
import { useEffect, useRef } from "react";
import alertSound from "../assets/emergency-alert-sound.mp3"; // Adjust the path to your sound file

const PrivateRoute = ({ children }) => {
  const { currentUser } = useStore((state) => ({
    currentUser: state.currentUser,
  }));

  const { isUserRegistered, isEmergencyPopupOpen, setEmergencyPopupState } =
    useStore((state) => ({
      isUserRegistered: state.isUserRegistered,
      isEmergencyPopupOpen: state.isEmergencyPopupOpen,
      setEmergencyPopupState: state.setEmergencyPopupState,
    }));

  const audioRef = useRef(null);
  const buttonRef = useRef(null);

  // Function to simulate a button click
  const simulateButtonClick = () => {
    if (buttonRef.current) {
      buttonRef.current.click();
    }
  };

  // Automatically play the audio when the emergency popup is opened
  useEffect(() => {
    if (isEmergencyPopupOpen) {
      simulateButtonClick();
    } else {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    }
  }, [isEmergencyPopupOpen]);

  return isUserRegistered ? (
    <div className="flex flex-col min-h-screen mx-auto">
      <EmergencyAlertPopup
        openAlertPopup={isEmergencyPopupOpen}
        setOpenAlertPopup={setEmergencyPopupState}
        currentUserId={currentUser._id}
        currentUserName={currentUser.name}
      />
      <UserNavigation />
      <main className="flex w-full items-center justify-center max-w-lg mx-auto mb-14">
        {children}
      </main>
      {/* Hidden button to trigger the audio */}
      <button
        ref={buttonRef}
        onClick={() => {
          if (audioRef.current && audioRef.current.paused) {
            audioRef.current.play().catch((error) => {
              console.error("Audio playback failed:", error);
            });
          }
        }}
        style={{ display: "none" }}
      >
        Play Sound
      </button>
      <audio ref={audioRef} src={alertSound} loop />
    </div>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
