// MarkdownRenderer.jsx
import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

// Custom components for Markdown elements
const components = {
  h1: ({ node, ...props }) => (
    <h1 className="text-3xl font-bold mt-2 mb-2" {...props} />
  ),
  h2: ({ node, ...props }) => (
    <h2 className="text-2xl font-semibold mt-3 mb-1" {...props} />
  ),
  h3: ({ node, ...props }) => (
    <h3 className="text-xl font-medium mt-2 mb-1" {...props} />
  ),
  p: ({ node, ...props }) => <p className="mb-4" {...props} />,
  ul: ({ node, ...props }) => <ul className="list-disc ml-5 mb-4" {...props} />,
  ol: ({ node, ...props }) => (
    <ol className="list-decimal ml-5 mb-4" {...props} />
  ),
  li: ({ node, ...props }) => <li className="mb-2" {...props} />,
  blockquote: ({ node, ...props }) => (
    <blockquote
      className="border-l-4 border-gray-300 pl-4 italic mb-4"
      {...props}
    />
  ),
  code: ({ node, ...props }) => (
    <code className="bg-gray-100 p-1 rounded" {...props} />
  ),
  // Add other elements as needed
};

const MarkdownRenderer = ({ children }) => (
  <ReactMarkdown components={components} remarkPlugins={[remarkGfm]}>
    {children}
  </ReactMarkdown>
);

export default MarkdownRenderer;
