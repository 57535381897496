import React from "react";
import { Marquee } from "@devnomic/marquee";
import "@devnomic/marquee/dist/index.css";

function features() {
  return (
    <div className="w-full">
      <div className="text-6xl text-slate-800 text-center font-bold mt-32">
        Features
      </div>
      <Marquee
        fade={true}
        direction="left"
        reverse={false}
        pauseOnHover={false}
        className="mt-20" // pass class to change gap or speed
        innerClassName="" // pass class to change gap or speed
      >
        <div className="border border-black rounded-lg py-60 px-40">
          Content 1
        </div>
        <div className="border border-black rounded-lg py-60 px-40">
          Content 2
        </div>
        <div className="border border-black rounded-lg py-60 px-40">
          Content 3
        </div>
        <div className="border border-black rounded-lg py-60 px-40">
          Content 4
        </div>
      </Marquee>
    </div>
  );
}

export default features;
