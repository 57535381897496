import React, { useEffect, useRef, useState } from "react";
import { MdArrowBackIosNew } from "react-icons/md";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { useNavigate, useLocation } from "react-router-dom";
import regionsDataJson from "../data/region.json";
import provincesDataJson from "../data/province.json";
import citiesDataJson from "../data/city.json";
import barangaysDataJson from "../data/barangay.json";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// Fix for default marker icon not showing in Leaflet
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerIconShadow from "leaflet/dist/images/marker-shadow.png";
import { MdOutlineClose } from "react-icons/md";
import { motion } from "framer-motion";
import useStore from "../store";
import { Divider } from "@mui/joy";
import axios from "axios";
import { toast } from "react-toastify";
import PinLocation from "../components/PinLocation";
import { ButtonLoader, DefaultLoader } from "../components/Loader";
import { IoArrowBack } from "react-icons/io5";
import { Loading } from "react-loading-dot";

const UserLocationMarker = ({ position, userProfile }) => {
  // Reference for the marker
  const markerRef = useRef(null);

  const usersIcon = new L.DivIcon({
    className: "",
    html: `
      <div class="relative flex w-[2.5rem] h-[2.5rem] ">
        <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-50"></span>
        <img src="${userProfile}" alt="profile" class="relative p-0.5 inline-flex w-[2.5rem] h-[2.5rem] rounded-full bg-white border-4 border-green-500 object-cover "/>
      </div>
    `,
    iconSize: [40, 40],
    iconAnchor: [25, 25],
    popupAnchor: [-3, -20],
  });

  // Open the popup when the component is mounted
  useEffect(() => {
    if (markerRef.current) {
      markerRef.current.openPopup();
    }
  }, []);

  return (
    <Marker position={position} icon={usersIcon} ref={markerRef}>
      <Popup autoPan={false}>You are here</Popup>
    </Marker>
  );
};

const UserLocation = () => {
  const { currentUser } = useStore((state) => ({
    currentUser: state.currentUser,
  }));
  const navigate = useNavigate();
  const location = useLocation();
  const [isEditLocation, setIsEditLocation] = useState(false);
  const { currentLocation } = location.state; // Extract currentLocation data

  const [address, setAddress] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedBarangay, setSelectedBarangay] = useState("");
  const [selectedRegionName, setSelectedRegionName] = useState("");
  const [selectedProvinceName, setSelectedProvinceName] = useState("");
  const [selectedCityName, setSelectedCityName] = useState("");
  const [selectedBarangayName, setSelectedBarangayName] = useState("");
  const [streetData, setStreetData] = useState("");

  const [regionsData, setRegionsData] = useState([]);
  const [provincesData, setProvincesData] = useState([]);
  const [citiesData, setCitiesData] = useState([]);
  const [barangaysData, setBarangaysData] = useState([]);

  const [currentLocationStatus, setCurrentLocationStatus] = useState("");
  const [loading, setLoading] = useState(true);

  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [errors, setErrors] = useState([]);
  const [resetPin, setResetPin] = useState(false);

  useEffect(() => {
    const fetchAddressFromDatabase = async () => {
      setLoading(true);
      try {
        if (currentUser?._id) {
          const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/server/registration/get-user-location/${currentUser._id}`
          );
          const locationData = response.data;

          if (locationData) {
            const {
              street = "",
              barangay = {},
              city = {},
              province = {},
              region = {},
              latitude,
              longitude,
            } = locationData;
            setLatitude(latitude);
            setLongitude(longitude);

            const fullAddress = `${street}, ${
              barangay.name || "Unknown Barangay"
            }, ${city.name || "Unknown City"}, ${
              province.name || "Unknown Province"
            }, ${region.name || "Unknown Region"}`;
            setCurrentLocationStatus(fullAddress);
          }
        }
      } catch (error) {
        console.error("Failed to fetch address from database:", error);
        toast.error("Failed to load address location.");
      } finally {
        setLoading(false);
      }
    };

    fetchAddressFromDatabase();
  }, [currentUser]);

  useEffect(() => {
    // Load the initial data from JSON files
    setRegionsData(regionsDataJson);
    setProvincesData(provincesDataJson);
    setCitiesData(citiesDataJson);
    setBarangaysData(barangaysDataJson);
  }, []);

  // Update location status when any of the selected values change
  useEffect(() => {
    const newAddress = `${streetData || currentLocation.street || ""}, ${
      selectedBarangayName || currentLocation.barangay || ""
    }, ${selectedCityName || currentLocation.city || ""}, ${
      selectedProvinceName || currentLocation.province || ""
    }, ${selectedRegionName || currentLocation.region || ""}`;
    setCurrentLocationStatus(newAddress);
  }, [
    selectedRegionName,
    selectedProvinceName,
    selectedCityName,
    selectedBarangayName,
    streetData,
    currentLocationStatus,
  ]);

  const updateLocationStatus = () => {
    const newAddress = `${streetData || currentLocation.street}, ${
      selectedBarangayName || currentLocation.barangay
    }, ${selectedCityName || currentLocation.city}, ${
      selectedProvinceName || currentLocation.province
    }, ${selectedRegionName || currentLocation.region}`;
    setCurrentLocationStatus(newAddress);
  };

  const handleRegionChange = (event) => {
    const region = regionsData.find(
      (r) => r.region_code === event.target.value
    );
    if (region) {
      setSelectedRegion(region.region_code);
      setSelectedRegionName(region.region_name);

      // Filter provinces based on selected region
      const filteredProvinces = provincesDataJson.filter(
        (p) => p.region_code === region.region_code
      );
      setProvincesData(filteredProvinces);
      setSelectedProvince(""); // Reset province selection
      setCitiesData([]); // Clear city selection
      setBarangaysData([]); // Clear barangay selection
      updateLocationStatus();
    }
  };

  const handleProvinceChange = (event) => {
    const province = provincesData.find(
      (p) => p.province_code === event.target.value
    );
    if (province) {
      setSelectedProvince(province.province_code);
      setSelectedProvinceName(province.province_name);

      // Filter cities based on selected province
      const filteredCities = citiesDataJson.filter(
        (c) => c.province_code === province.province_code
      );
      setCitiesData(filteredCities);
      setSelectedCity(""); // Reset city selection
      setBarangaysData([]); // Clear barangay selection
      updateLocationStatus();
    }
  };

  const handleCityChange = (event) => {
    const city = citiesData.find((c) => c.city_code === event.target.value);
    if (city) {
      setSelectedCity(city.city_code);
      setSelectedCityName(city.city_name);

      // Filter barangays based on selected city
      const filteredBarangays = barangaysDataJson.filter(
        (b) => b.city_code === city.city_code
      );
      setBarangaysData(filteredBarangays);
      setSelectedBarangay(""); // Reset barangay selection
      updateLocationStatus();
    }
  };

  const handleBarangayChange = (event) => {
    const barangay = barangaysData.find(
      (b) => b.brgy_code === event.target.value
    );
    if (barangay) {
      setSelectedBarangay(barangay.brgy_code);
      setSelectedBarangayName(barangay.brgy_name);
      updateLocationStatus();
    }
  };

  const handleStreetChange = (e) => {
    setStreetData(e.target.value);
    updateLocationStatus();
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleEditLocation = () => {
    setIsEditLocation(!isEditLocation);
  };

  const handleLocationChange = (lat, lng) => {
    setLatitude(lat);
    setLongitude(lng);
  };
  const resetPinLocation = () => {
    setResetPin((prev) => !prev); // Toggle to trigger reset
  };

  // Function to check if all fields are set
  const validateFields = () => {
    const errorMessages = {};

    if (!selectedRegion) {
      errorMessages.region = "Region is required.";
    }

    if (!selectedProvince) {
      errorMessages.province = "Province is required.";
    }

    if (!selectedCity) {
      errorMessages.city = "City is required.";
    }

    if (!selectedBarangay) {
      errorMessages.barangay = "Barangay is required.";
    }

    if (!streetData) {
      errorMessages.street = "Street is required.";
    }

    if (!latitude || !longitude) {
      errorMessages.pinLocation = "Pin Location is required.";
    }

    setErrors(errorMessages);
    return Object.keys(errorMessages).length === 0; // Return true if no errors
  };

  const handleSaveLocation = async () => {
    // Validate fields first
    const isValid = validateFields();
    if (!isValid) {
      // If validation fails, stop the execution
      toast.error("Please fill in all the required fields.");
      return;
    }

    if (!currentUser?._id) {
      toast.error("User ID is missing");
      return;
    }

    const updatedAddressLocation = {
      barangay: {
        name: selectedBarangayName || currentLocation.barangay.name,
        code: selectedBarangay || currentLocation.barangay.code,
      },
      city: {
        name: selectedCityName || currentLocation.city.name,
        code: selectedCity || currentLocation.city.code,
      },
      province: {
        name: selectedProvinceName || currentLocation.province.name,
        code: selectedProvince || currentLocation.province.code,
      },
      region: {
        name: selectedRegionName || currentLocation.region.name,
        code: selectedRegion || currentLocation.region.code,
      },
      street: streetData || currentLocation.street, // This will use the new streetData
      latitude: latitude || currentLocation.latitude,
      longitude: longitude || currentLocation.longitude,
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/server/registration/update-location/${currentUser._id}`,
        { addressLocation: [updatedAddressLocation] }
      );

      if (response.status === 200) {
        toast.success("Location updated successfully!");
        setIsEditLocation(false);

        // Update Zustand store
        useStore.setState((state) => ({
          currentUser: {
            ...state.currentUser,
            addressLocation: [updatedAddressLocation], // Update the addressLocation
          },
        }));

        // Update local storage if needed
        localStorage.setItem(
          "currentUser",
          JSON.stringify({
            ...currentUser,
            addressLocation: [updatedAddressLocation], // Update addressLocation in local storage
          })
        );

        // Reset all fields to empty or default values after successful update
        setSelectedRegion("");
        setSelectedProvince("");
        setSelectedCity("");
        setSelectedBarangay("");
        setStreetData(""); // Make sure to reset this
        setLatitude(null);
        setLongitude(null);
        resetPinLocation();
      }
    } catch (error) {
      toast.error("Failed to update location.");
      console.error(error);
    }
  };

  const openCloseAnimation = {
    open: {
      y: 10,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30,
        duration: 0.1,
      },
    },
    closed: {
      y: 650,
      opacity: 0,
      transition: {
        stiffness: 300,
        damping: 30,
        duration: 0.2,
      },
    },
  };

  return (
    <div className="w-full relative">
      {/* Fixed header */}
      <div className="w-full max-w-lg fixed top-0 z-[994] bg-white drop-shadow-sm">
        <div className="flex justify-between items-center px-4 py-4">
          <div className="flex font-semibold gap-4">
            <IoArrowBack
              size={20}
              onClick={handleGoBack}
              className="self-center cursor-pointer"
            />
            My Address
          </div>
        </div>
      </div>

      {/* Display the map */}
      <div className="w-full">
        {loading ? (
          <div className="h-[calc(100vh-7rem)] w-full flex items-center justify-center">
            <div>
              <div>Loading map...</div>
            </div>
          </div>
        ) : (
          latitude !== null &&
          longitude !== null && (
            <div className="h-[calc(100vh-7rem)] w-full mt-12">
              <MapContainer
                center={[latitude, longitude]}
                zoom={16}
                scrollWheelZoom={false}
                className="h-full w-full z-[993]"
              >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <UserLocationMarker
                  position={[latitude, longitude]}
                  userProfile={currentUser.profilePicture}
                ></UserLocationMarker>
              </MapContainer>
            </div>
          )
        )}
      </div>

      {/* Location status */}
      <div className="fixed bottom-20 z-[995] w-full p-6 max-w-lg">
        <div
          onClick={handleEditLocation}
          className="w-full p-4 rounded-xl bg-white/65 backdrop-blur max-w-lg"
        >
          <div className="flex justify-between  items-start mb-3">
            <div className="font-semibold flex gap-3 items-center">
              <img
                src={currentUser.profilePicture}
                className="w-7 h-7 rounded-full object-cover"
                alt="Profile"
              />
              {currentUser.name}
            </div>
            <div className="font-semibold text-blue-500 text-sm cursor-pointer">
              Edit
            </div>
          </div>

          <Divider />
          <div className="flex gap-3 items-center mt-1">
            {loading ? (
              <div className="mx-auto p-2">
                <div>Loading...</div>
              </div>
            ) : (
              <div>{currentLocationStatus || "Address not available"}</div>
            )}
          </div>
        </div>
      </div>

      {/* Edit Location */}
      <div>
        <div
          onClick={() => setIsEditLocation(false)}
          className={`fixed max-w-lg mx-auto inset-0 z-[996] bg-black/50 ${
            isEditLocation ? "block" : "hidden"
          }`}
        ></div>

        <motion.div
          variants={openCloseAnimation}
          initial="closed"
          animate={isEditLocation ? "open" : "closed"}
          className="fixed bottom-16 bg-white px-4 py-2 pb-8 rounded-t-2xl z-[997] w-full max-w-lg h-full top-2 "
        >
          <div className="flex justify-between py-4 ">
            <div>Edit your location</div>
            <div className="bg-gray-50 p-2 rounded-full self-center cursor-pointer">
              <MdOutlineClose
                size={20}
                onClick={() => setIsEditLocation(false)}
              />
            </div>
          </div>

          <div className="h-[calc(100vh-11rem)] overflow-auto no-scrollbar">
            <div className="mb-2">
              {Object.keys(errors).length > 0 && (
                <div className="error-message bg-red-200 p-3 rounded-md mb-4">
                  {Object.keys(errors).map((field, index) => (
                    <p key={index} className="text-red-600">
                      {errors[field]}
                    </p>
                  ))}
                </div>
              )}
            </div>
            <div className="p-2 bg-blue-100 text-blue-500 rounded-md text-sm mb-4">
              {/* Current location status */}
              <p>Current location:</p>
              <p>{currentLocationStatus}</p>
            </div>

            {/* Philippine Address Selectors */}
            <div className="w-full mb-4">
              <FormControl
                className="w-full bg-gray-100 rounded-md form-control"
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
              >
                <Select
                  value={selectedRegion}
                  onChange={handleRegionChange}
                  input={<OutlinedInput placeholder="Select Region" />}
                  displayEmpty
                >
                  <MenuItem value="">
                    <p>Select Region</p>
                  </MenuItem>
                  {regionsData.map((region) => (
                    <MenuItem
                      key={region.region_code}
                      value={region.region_code}
                    >
                      {region.region_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="w-full mb-4">
              <FormControl
                className="w-full bg-gray-100 rounded-md form-control"
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                disabled={!selectedRegion}
              >
                <Select
                  value={selectedProvince}
                  onChange={handleProvinceChange}
                  input={<OutlinedInput placeholder="Select Province" />}
                  displayEmpty
                >
                  <MenuItem value="">
                    <p>Select Province</p>
                  </MenuItem>
                  {provincesData.map((province) => (
                    <MenuItem
                      key={province.province_code}
                      value={province.province_code}
                    >
                      {province.province_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="w-full mb-4">
              <FormControl
                className="w-full bg-gray-100 rounded-md form-control"
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                disabled={!selectedProvince}
              >
                <Select
                  value={selectedCity}
                  onChange={handleCityChange}
                  input={<OutlinedInput placeholder="Select City" />}
                  displayEmpty
                >
                  <MenuItem value="">
                    <p>Select City</p>
                  </MenuItem>
                  {citiesData.map((city) => (
                    <MenuItem key={city.city_code} value={city.city_code}>
                      {city.city_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="w-full mb-4">
              <FormControl
                className="w-full bg-gray-100 border border-gray-300 rounded-md form-control"
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                disabled={!selectedCity}
              >
                <Select
                  value={selectedBarangay}
                  onChange={handleBarangayChange}
                  input={<OutlinedInput placeholder="Select Barangay" />}
                  displayEmpty
                >
                  <MenuItem value="">
                    <p>Select Barangay</p>
                  </MenuItem>
                  {barangaysData.map((barangay) => (
                    <MenuItem
                      key={barangay.brgy_code}
                      value={barangay.brgy_code}
                    >
                      {barangay.brgy_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            {/* Street Address Input */}
            <div className="w-full">
              <p className="text-sm mb-2 mt-4">
                Street Name, Building, House No.
              </p>
              <input
                value={streetData}
                onChange={handleStreetChange}
                placeholder="Street Name"
                className="w-full bg-gray-100 dark:bg-secondary-surface-color text-text dark:text-text_dark rounded-md p-4 outline-none"
              />
            </div>

            {/* Pin User Location */}
            <PinLocation
              onLocationChange={handleLocationChange}
              reset={resetPin}
            />

            <button
              onClick={handleSaveLocation}
              className="w-full mt-4 p-2 bg-blue-500 text-white rounded-lg"
            >
              Update Location
            </button>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default UserLocation;
