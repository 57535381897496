import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import ChatList from "./ChatList";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ChatHeader from "./ChatHeader";

const queryClient = new QueryClient();

const ChatLayout = () => {
  // Manage the chat list open/close state, initialized from localStorage
  const [isChatListOpen, setIsChatListOpen] = useState(
    JSON.parse(localStorage.getItem("isChatListOpen")) || false
  );

  // Effect to handle screen size changes
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1024) {
        setIsChatListOpen(false); // Close the chat list on mobile
        localStorage.setItem("isChatListOpen", "false"); // Set to false in localStorage
      } else {
        // Restore the state from localStorage when back on desktop
        const storedState =
          JSON.parse(localStorage.getItem("isChatListOpen")) || false;
        setIsChatListOpen(storedState);
      }
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Call the function initially to set the correct state
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Function to toggle the chat list and save the state in localStorage
  const toggleChatList = () => {
    const newState = !isChatListOpen;
    setIsChatListOpen(newState);
    localStorage.setItem("isChatListOpen", JSON.stringify(newState));
  };

  return (
    <QueryClientProvider client={queryClient}>
      <div className="w-full">
        <div className={`${isChatListOpen ? "block" : "hidden"} lg:block`}>
          <ChatList
            isOpen={isChatListOpen}
            setIsChatListOpen={setIsChatListOpen}
          />
        </div>
        <div className="flex flex-col w-full">
          <ChatHeader
            toggleChatList={toggleChatList}
            isChatListOpen={isChatListOpen}
          />
          <div className="w-full">
            <Outlet />
          </div>
        </div>
      </div>
    </QueryClientProvider>
  );
};

export default ChatLayout;
