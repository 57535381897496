import { FiLoader } from "react-icons/fi";

// DefaultLoader Component
export const DefaultLoader = ({ color = "black" }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center">
      <FiLoader size={20} className={`animate-spin text-${color}`} />
    </div>
  );
};

// ButtonLoader Component
export const ButtonLoader = ({ color = "black" }) => {
  return (
    <div className="flex justify-center  bg-transparent">
      <FiLoader size={20} className={`animate-spin text-${color}`} />
    </div>
  );
};
