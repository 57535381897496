import React, { useState, useEffect, useRef } from "react";
import { IoMdSend, IoMdClose } from "react-icons/io";
import Upload from "./Upload";
import { IKImage } from "imagekitio-react";
import model from "../../lib/gemini";
import { useQueryClient } from "@tanstack/react-query";
import { ButtonLoader } from "../Loader";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import { FreeMode } from "swiper/modules";

const NewPrompt = ({ onSendMessage, data }) => {
  const [text, setText] = useState("");
  const [img, setImg] = useState({
    isLoading: false,
    error: "",
    dbData: {},
    aiData: {},
  });
  const [isDisabled, setIsDisabled] = useState(true);
  const [shouldProcessLastMessage, setShouldProcessLastMessage] =
    useState(false);

  const queryClient = useQueryClient();

  const chatId = data?._id;

  const add = async (text, imageUrl, isInitial) => {
    if (!isInitial) {
      onSendMessage({ text, imageUrl, isUser: true });
    }

    try {
      // Prepare chat history for Gemini API
      const history = data.history.map((item) => ({
        role: item.role,
        parts: [{ text: item.parts[0]?.text || "" }],
      }));

      // Add the new user message
      history.push({
        role: "user",
        parts: [{ text }],
      });

      // Start chat with history
      const chat = model.startChat({ history });

      let result; // Initialize result
      let answer = ""; // Initialize answer

      // Handle streaming for AI response
      if (Object.entries(img.aiData).length) {
        const streamResult = await model.generateContentStream(
          Object.entries(img.aiData).length ? [img.aiData, text] : [text]
        );

        for await (const chunk of streamResult.stream) {
          const chunkText = await chunk.text();
          answer += chunkText;

          onSendMessage({ answer, isPartial: true });
        }

        onSendMessage({ answer, isPartial: false });
        result = { response: { text: () => answer } };
      } else {
        // Fallback to non-streaming message sending if no image data
        result = text.trim()
          ? await chat.sendMessage(text)
          : { response: { text: () => "" } }; // Send empty text if image is only content
      }

      // Send final response
      onSendMessage({ answer: result.response.text(), isPartial: false });

      // Save AI response in database
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/server/chat/chats/${chatId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          body: JSON.stringify({
            aiResponse: result.response.text(),
            role: "model",
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update chat");
      }
    } catch (error) {
      if (error.message.includes("SAFETY")) {
        console.error("Error during chat processing:", error);
        setImg((prev) => ({
          ...prev,
          error:
            "Your request was blocked due to safety restrictions. Please try a different query.",
          error: error.message.includes("SAFETY")
            ? "Your request was blocked due to safety restrictions. Please try a different query."
            : "An unexpected error occurred. Please try again later.",
        }));
      } else {
        setImg((prev) => ({
          ...prev,
          error: "An unexpected error occurred. Please try again later.",
        }));
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!text.trim() && !img.dbData?.filePath) return;

    const imageUrl = img.dbData?.filePath || null;

    setText("");
    setImg({
      isLoading: false,
      error: "",
      dbData: {},
      aiData: {},
    });

    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/server/chat/chats/${chatId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify({
          text: text.trim() || null,
          imageUrl,
          role: "user",
        }),
      }
    );

    if (!response.ok) {
      console.error("Failed to update chat:", response.statusText);
      return;
    }

    await add(text.trim(), imageUrl, false);
  };

  const handleCancelImage = () => {
    setImg({
      isLoading: false,
      error: "",
      dbData: {},
      aiData: {},
    });
  };

  const handleKeyDown = (e) => {
    if (
      e.key === "Enter" &&
      !e.shiftKey &&
      (text.trim() || img.dbData?.filePath)
    ) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  useEffect(() => {
    const handleGlobalKeyPress = (e) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        if (img.dbData?.filePath && text.trim() === "") {
          handleSubmit(e);
        } else if (text.trim() || img.dbData?.filePath) {
          handleSubmit(e);
        }
      }
    };

    window.addEventListener("keydown", handleGlobalKeyPress);

    return () => {
      window.removeEventListener("keydown", handleGlobalKeyPress);
    };
  }, [img.dbData?.filePath, text]);

  const hasRun = useRef(false);
  const textAreaRef = useRef(null);

  useEffect(() => {
    if (textAreaRef.current) {
      const textArea = textAreaRef.current;
      textArea.style.height = "auto";
      textArea.style.height = `${Math.min(textArea.scrollHeight, 240)}px`;
    }
  }, [text]);

  useEffect(() => {
    if (data && data.history && !hasRun.current) {
      // Check if there is only one message in history and if it belongs to the user
      if (data.history.length === 1 && data.history[0].role === "user") {
        setShouldProcessLastMessage(true); // Trigger AI response
      }

      hasRun.current = true;
    }
  }, [data]);

  useEffect(() => {
    if (data && data.history && shouldProcessLastMessage) {
      if (data.history.length > 0) {
        const lastMessage = data.history[data.history.length - 1];
        add(lastMessage.parts[0].text, lastMessage.img, true);
      }

      setShouldProcessLastMessage(false);
    }
  }, [data, shouldProcessLastMessage]);

  useEffect(() => {
    setIsDisabled(!(text.trim() || img.dbData?.filePath));
  }, [text, img.dbData?.filePath]);

  const handlePromptClick = (promptText) => {
    setText(promptText); // Set the prompt text in the input
    add(promptText, null, false); // Automatically submit it
    setText(""); // Clear the input field after submitting
  };

  return (
    <div className=" px-3">
      <div className="relative">
        {img.isLoading && (
          <div className="w-[10rem] h-[10rem]  flex justify-center items-center bg-gray-800 opacity-60 rounded-xl absolute bottom-0 left-2 backdrop-blur-lg z-20 my-2">
            <ButtonLoader color="white" />
          </div>
        )}
        {img.dbData?.filePath && (
          <div>
            <IKImage
              urlEndpoint={process.env.REACT_APP_IMAGE_KIT_ENDPOINT}
              path={img.dbData?.filePath}
              className="absolute bottom-0 left-2 w-[10rem] h-[10rem] rounded-xl bg-gray-300 dark:bg-secondary-surface-color p-2 my-2"
            />
            <button
              type="button"
              onClick={handleCancelImage}
              className="absolute bottom-[7.5rem] left-[7.5rem] p-1 bg-gray-600 opacity-35 hover:opacity-90 text-white rounded-full "
            >
              <IoMdClose size={22} />
            </button>
          </div>
        )}
        {img.error && (
          <div className="text-red-500 text-center text-sm my-2">
            {img.error}
          </div>
        )}
      </div>

      {/* Swiper for prompts */}
      <div className="pb-2 pt-4 flex justify-end items-baseline">
        <Swiper
          spaceBetween={10}
          slidesPerView="auto"
          freeMode={true}
          modules={[FreeMode]}
        >
          <SwiperSlide className="w-auto max-w-64 self-end">
            <div
              onClick={() =>
                handlePromptClick("Find the Nearest Evacution Center")
              }
              className="p-2 bg-blue-100 text-blue-500 border border-blue-300 rounded-xl text-sm select-none"
            >
              Find the Nearest Evacution Center
            </div>
          </SwiperSlide>
          <SwiperSlide className="w-auto max-w-64 self-end">
            <div
              onClick={() => handlePromptClick("How to Perform CPR")}
              className="p-2 bg-blue-100 text-blue-500 border border-blue-300 rounded-xl text-sm select-none"
            >
              How to Perform CPR
            </div>
          </SwiperSlide>
          <SwiperSlide className="w-auto max-w-64 self-end">
            <div
              onClick={() =>
                handlePromptClick("Evacuation Guidelines for flood")
              }
              className="p-2 bg-blue-100 text-blue-500 border border-blue-300 rounded-xl text-sm select-none"
            >
              Evacuation Guidelines for flood
            </div>
          </SwiperSlide>
          <SwiperSlide className="w-auto self-end">
            <div
              onClick={() =>
                handlePromptClick(
                  "What essential items should I include in my emergency kit?"
                )
              }
              className="p-2 bg-blue-100 text-blue-500 border border-blue-300 rounded-xl text-sm select-none"
            >
              What essential items should I include in my emergency kit?
            </div>
          </SwiperSlide>
          <SwiperSlide className="w-auto max-w-64 self-end">
            <div
              onClick={() =>
                handlePromptClick("What to Do After an Earthquake?")
              }
              className="p-2 bg-blue-100 text-blue-500 border border-blue-300 rounded-xl text-sm select-none"
            >
              What to Do After an Earthquake?
            </div>
          </SwiperSlide>
          <SwiperSlide className="w-auto max-w-64 self-end">
            <div
              onClick={() =>
                handlePromptClick("First Aid for Wounds and Injuries")
              }
              className="p-2 bg-blue-100 text-blue-500 border border-blue-300 rounded-xl text-sm select-none"
            >
              First Aid for Wounds and Injuries
            </div>
          </SwiperSlide>
          <SwiperSlide className="w-auto max-w-64 self-end">
            <div
              onClick={() =>
                handlePromptClick("Prepare for a Natural Disaster")
              }
              className="p-2 bg-blue-100 text-blue-500 border border-blue-300 rounded-xl text-sm select-none"
            >
              Prepare for a Natural Disaster
            </div>
          </SwiperSlide>
          <SwiperSlide className="w-auto max-w-64 self-end">
            <div
              onClick={() => handlePromptClick("What to Do in a Power Outage?")}
              className="p-2 bg-blue-100 text-blue-500 border border-blue-300 rounded-xl text-sm select-none"
            >
              What to Do in a Power Outage?
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

      <form className="relative flex items-center" onSubmit={handleSubmit}>
        <label htmlFor="file" className="absolute bottom-3 left-3 ">
          <Upload setImg={setImg} />
        </label>
        <input id="file" type="file" multiple={false} hidden />
        <textarea
          ref={textAreaRef}
          name="text"
          autoComplete="off"
          placeholder="Ask anything..."
          value={text}
          rows={1}
          onChange={(e) => setText(e.target.value)}
          onKeyDown={handleKeyDown}
          className="w-full rounded-2xl text-text dark:text-text_dark  dark:bg-secondary-dark-color pl-10 pr-12 py-3 outline-none resize-none"
          style={{
            overflowY:
              textAreaRef.current?.scrollHeight > 240 ? "auto" : "hidden",
          }}
        />
        <button
          type="submit"
          className={`absolute right-3 bottom-1.5 p-2 rounded-full  text-white items-center  ${
            isDisabled ? "bg-gray-400" : "bg-blue-500"
          }`}
          disabled={isDisabled}
        >
          <IoMdSend size={20} />
        </button>
      </form>
    </div>
  );
};

export default NewPrompt;
