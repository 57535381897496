import React, { useState } from "react";
import { PhoneIcon, LockClosedIcon } from "@heroicons/react/24/outline";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios"; // Make sure to install axios
import useStore from "../store";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [contact, setContact] = useState(""); // State for contact number
  const [password, setPassword] = useState(""); // State for password
  const [errorMessage, setErrorMessage] = useState(""); // State for error message
  const navigate = useNavigate(); // For navigation after login
  const { setUserRegistered, setCurrentUser } = useStore();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    setErrorMessage(""); // Clear any previous error messages

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/server/registration/user/login`,
        {
          phone: contact, // Send contact as phone
          password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      if (response.data) {
        // Check if response data exists
        const citizen = response.data; // Get citizen object from response

        const userData = {
          _id: citizen._id,
          userID: citizen.userID,
          name: citizen.name,
          profilePicture: citizen.profilePicture,
          phone: citizen.phone,
          age: citizen.age,
          gender: citizen.gender,
          addressLocation: citizen.addressLocation,
          dateJoined: citizen.dateJoined,
          termsAccepted: citizen.termsAccepted,
          privacyAccepted: citizen.privacyAccepted,
        };

        localStorage.removeItem("registrationFormData");
        localStorage.removeItem("registrationStep");
        localStorage.removeItem("userLocation");

        localStorage.setItem("userRegistered", "true");
        localStorage.setItem("access_token", response.data.access_token);
        setUserRegistered(true);
        setCurrentUser(userData);
        navigate("/user-home");
      }
    } catch (error) {
      console.error("Login error:", error.response?.data || error.message);
      setErrorMessage("Incorrect phone number or password."); // Set error message
    }
  };

  return (
    <div className="mt-4 max-w-lg mx-auto px-8 py-2">
      <div className="text-center mb-10 mt-16">
        <h1 className="font-semibold text-3xl mb-2">Login Now</h1>
        <p className="text-sm">Login using your contact number and password</p>
      </div>

      {errorMessage && (
        <div className="bg-red-100 text-red-500 p-3 rounded-md mb-4 text-sm text-center">
          {errorMessage}
        </div>
      )}

      <form onSubmit={handleSubmit}>
        <div>
          <div className="mt-2 mb-4 relative">
            <input
              type="number"
              id="contact"
              placeholder="Contact number"
              value={contact}
              onChange={(e) => setContact(e.target.value)} // Update contact state
              className="form-control py-3 px-4 rounded-xl pl-10 text-sm"
              required // Make the field required
            />
            <PhoneIcon className="absolute top-1/2 left-3 h-[1.1rem] w-[1.1rem] text-gray-400 transform -translate-y-1/2" />
          </div>
          <div className="mt-2 mb-4 relative">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)} // Update password state
              className="form-control py-3 px-4 rounded-xl pl-10 pr-10 text-sm"
              required // Make the field required
            />
            <LockClosedIcon className="absolute top-1/2 left-3 h-[1.1rem] w-[1.1rem] text-gray-400 transform -translate-y-1/2" />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute top-1/2 right-4 transform -translate-y-1/2"
              aria-label={showPassword ? "Hide password" : "Show password"}
            >
              {showPassword ? (
                <EyeSlashIcon className="h-[1.1rem] w-[1.1rem] text-gray-400" />
              ) : (
                <EyeIcon className="h-[1.1rem] w-[1.1rem] text-gray-400" />
              )}
            </button>
          </div>

          <button
            type="submit"
            className="p-2 bg-blue-500 text-white rounded-xl w-full text-center mt-2 mb-4"
          >
            Login
          </button>
        </div>
      </form>
      <div className="text-center text-sm">
        Don't have an account?{" "}
        <Link to="/registration-and-terms">
          <span className="text-blue-500">Register</span>
        </Link>
      </div>
    </div>
  );
};

export default Login;
