import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MdArrowBackIosNew } from "react-icons/md";
import { MdFlood } from "react-icons/md";
import { RiEarthquakeFill, RiFireFill } from "react-icons/ri";
import { FaBriefcaseMedical } from "react-icons/fa";
import { BsFillCloudLightningRainFill } from "react-icons/bs";
import { GiNuclearPlant } from "react-icons/gi";
import { HiMiniBellAlert } from "react-icons/hi2";
import profile from "../assets/profile.png";
import { formatDistanceToNow } from "date-fns";
import { enGB } from "date-fns/locale";
import { DefaultLoader } from "../components/Loader";

const getIconAndBgColor = (type) => {
  switch (type) {
    case "Typhoon":
      return {
        icon: <MdFlood size={22} />,
        bgColor: "bg-blue-300 text-blue-500",
      };
    case "Earthquake":
      return {
        icon: <RiEarthquakeFill size={22} />,
        bgColor: "bg-stone-300 text-stone-500",
      };
    case "Medical":
      return {
        icon: <FaBriefcaseMedical size={22} />,
        bgColor: "bg-green-300 text-green-500",
      };
    case "Fire":
      return {
        icon: <RiFireFill size={22} />,
        bgColor: "bg-red-300 text-red-500",
      };
    case "Flood":
      return {
        icon: <BsFillCloudLightningRainFill size={22} />,
        bgColor: "bg-blue-300 text-blue-500",
      };
    case "Nuclear":
      return {
        icon: <GiNuclearPlant size={22} />,
        bgColor: "bg-orange-300 text-orange-500",
      };
    case "Thunderstorm":
      return {
        icon: <BsFillCloudLightningRainFill size={22} />,
        bgColor: "bg-purple-300 text-purple-500",
      };
    case "General":
    default:
      return {
        icon: <HiMiniBellAlert size={22} />,
        bgColor: "bg-yellow-300 text-yellow-500",
      };
  }
};

// Utility function to format timestamps
const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  return formatDistanceToNow(date, { addSuffix: true, locale: enGB });
};

const ViewUserNotif = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [notification, setNotification] = useState(null);
  const [loading, setLoading] = useState(true); // State to track loading
  const [error, setError] = useState(null); // State to handle errors

  useEffect(() => {
    const fetchNotification = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/server/alert/view-alert/${id}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setNotification(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 700);
      }
    };

    fetchNotification();
  }, [id]);

  function handleGoBack() {
    navigate(-1);
  }

  const { icon, bgColor } = getIconAndBgColor(notification?.type || "General");

  return (
    <div className="w-full">
      <div className="w-full max-w-lg fixed top-0 z-10 bg-white drop-shadow-sm">
        <div className="px-4 py-4">
          <MdArrowBackIosNew
            onClick={handleGoBack}
            className="absolute top-5 left-4 cursor-pointer"
          />
          <div className="text-center font-semibold">Notification Details</div>
        </div>
      </div>
      {loading ? (
        <div>
          <DefaultLoader />
        </div>
      ) : !notification ? (
        <div className="text-center py-40">
          <p className="text-gray-500 text-lg">Notification not found</p>
        </div>
      ) : (
        <div className="bg-white mx-4 rounded-xl p-4 mt-20">
          <div className="flex gap-4 mb-6">
            <img
              src={profile}
              alt="profile"
              className="w-12 h-12 object-cover rounded-full"
            />
            <div>
              <p className="font-semibold">BDRRMO</p>
              <p className="text-xs text-slate-500">
                bdrrmo@gmail.com | 09123456789
              </p>
            </div>
          </div>
          <div className="bg-gray-50 p-2 rounded-lg">
            <div
              className={`${bgColor} bg-opacity-20 rounded-full p-4 w-14 h-14 mx-auto mb-4`}
            >
              {icon}
            </div>
            <p className="font-semibold text-center">{notification.title}</p>
            <p className="mt-2">{notification.description}</p>
            <p className="text-xs text-gray-400 my-1">
              {formatTimestamp(notification.timestamp)}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewUserNotif;
