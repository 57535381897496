import { useQuery } from "@tanstack/react-query";
import React, { useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { HiSparkles } from "react-icons/hi";
import { motion } from "framer-motion";
import { IoChatbubblesOutline } from "react-icons/io5";

const ChatList = ({ isOpen, setIsChatListOpen }) => {
  const location = useLocation();
  const currentChatId = location.pathname.split("/").pop(); // Extract the chatId from the URL

  const chatListRef = useRef();

  const { isLoading, error, data } = useQuery({
    queryKey: ["userChats"],
    queryFn: () =>
      fetch(`${process.env.REACT_APP_API_BASE_URL}/server/chat/userchats`, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }).then((res) => {
        if (!res.ok) {
          throw new Error("Failed to fetch chats");
        }
        return res.json();
      }),
  });

  const Sidebar_animation = {
    open: {
      x: 0,
      width: "18rem",
      transition: {
        damping: 40,
        stiffness: 300,
        duration: 0.3,
      },
    },
    closed: {
      width: 0,
      x: -400,
      transition: {
        damping: 40,
        duration: 0.4,
      },
    },
  };

  const handleChatItemClick = () => {
    if (window.innerWidth < 1024) {
      setIsChatListOpen(false); // Close the chat list on mobile after navigation
    }
  };

  return (
    <div className="">
      <div
        onClick={() => setIsChatListOpen(false)}
        className={`md:hidden fixed inset-0 z-[996] bg-black/50 ${
          isOpen ? "block" : "hidden"
        }`}
      ></div>
      <motion.div
        ref={chatListRef}
        variants={Sidebar_animation}
        initial={isOpen ? "open" : "closed"}
        animate={isOpen ? "open" : "closed"}
        className="bg-white dark:bg-primary-dark-color h-screen p-3 z-[997] rounded-l-none rounded-r-xl md:rounded-xl left-0 flex flex-col top-0 overflow-hidden fixed md:h-[calc(100vh-150px)]"
      >
        <div className="mb-4">
          <Link to="/ai-chats" onClick={handleChatItemClick}>
            <div className="flex justify-between p-3 font-semibold bg-gradient-to-r from-blue-300 to-blue-500 text-white rounded-lg">
              Start a new Chat{" "}
              <HiSparkles size={22} className="self-center text-white" />
            </div>
          </Link>
        </div>

        <div className="flex-1 overflow-auto px-2">
          <div className="text-xs text-gray-500 mb-2">Recent Chats</div>
          <div className="flex flex-col-reverse">
            {isLoading ? (
              "Loading..."
            ) : error ? (
              <div className="text-red-500">Something went wrong!</div>
            ) : data && data.length > 0 ? (
              data.map((chat) => (
                <Link
                  key={chat._id}
                  to={`/ai-chats/chat/${chat._id}`}
                  onClick={handleChatItemClick}
                  className={`p-2 rounded-lg ${
                    currentChatId === chat._id
                      ? "bg-blue-500 text-white"
                      : "hover:text-blue-500 "
                  }`}
                >
                  <div className="whitespace-nowrap overflow-hidden text-ellipsis">
                    {chat.title}
                  </div>
                </Link>
              ))
            ) : (
              <div className="items-center mx-auto mt-10">
                <div className="flex bg-gray-50 rounded-full p-2 m-2 w-16 h-16 mx-auto justify-center">
                  <IoChatbubblesOutline
                    className="m-auto text-gray-500"
                    size={30}
                  />
                </div>
                <p className="text-slate-500 text-sm">No chats available</p>
              </div>
            )}
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default ChatList;
