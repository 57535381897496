import React from "react";
import mockup from "../assets/mockup.png";

function HowItWorks() {
  return (
    <div className="w-full sm:w-2/4 m-auto text-center pt-72">
      <div className="text-6xl text-slate-800 font-bold">How It Works</div>

      {/* First row */}
      <div className="flex flex-col-reverse sm:flex-row justify-center sm:justify-between text-start mt-20">
        <div className="relative w-full sm:w-52 mt-8 sm:mt-0">
          {/* Blue background */}
          <div className="absolute top-40 -left-20 w-full sm:w-96 h-60 bg-pallete-3 rounded-xl z-0 opacity-90 sm:block hidden"></div>
          {/* Mockup image */}
          <img
            src={mockup}
            alt="mockup-image"
            className="w-full sm:w-56 relative transform scale-custom sm:scale-110 z-10"
          />
        </div>
        <div className="self-center mt-8 sm:mt-0 sm:ml-8">
          <div className="text-3xl font-bold pb-3">RESPO</div>
          <div className="w-60 mx-auto sm:mx-0">
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Error,
            reiciendis dolore. Optio maxime, quae aperiam adipisci labore, quas
            dolor placeat minima nam, fugit est delectus provident. Maiores eius
            debitis ullam.
          </div>
          <div>
            <button className="bg-pallete-5 text-white py-2 px-4 mt-5">
              Learn More
            </button>
          </div>
        </div>
      </div>

      {/* Second row */}
      <div className="flex flex-col sm:flex-row justify-center sm:justify-between text-start mt-20">
        <div className="self-center mt-8 sm:mt-0 sm:mr-8">
          <div className="text-3xl font-bold pb-3">RESPO</div>
          <div className="w-60 mx-auto sm:mx-0">
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Error,
            reiciendis dolore. Optio maxime, quae aperiam adipisci labore, quas
            dolor placeat minima nam, fugit est delectus provident. Maiores eius
            debitis ullam.
          </div>
          <div>
            <button className="border border-pallete-5 py-2 px-4 mt-5">
              Learn More
            </button>
          </div>
        </div>
        <div className="relative w-full sm:w-52 mt-8 sm:mt-0">
          {/* Blue background */}
          <div className="absolute top-40 -left-20 w-full sm:w-96 h-60 bg-pallete-3 rounded-xl z-0 opacity-90 sm:block hidden"></div>
          {/* Mockup image */}
          <img
            src={mockup}
            alt="mockup-image"
            className="w-full sm:w-56 relative transform scale-custom sm:scale-110 z-10"
          />
        </div>
      </div>

      {/* Third row */}
      <div className="flex flex-col-reverse sm:flex-row justify-center sm:justify-between text-start mt-20">
        <div className="relative w-full sm:w-52 mt-8 sm:mt-0">
          {/* Blue background */}
          <div className="absolute top-40 -left-20 w-full sm:w-96 h-60 bg-pallete-3 rounded-xl z-0 opacity-90 sm:block hidden"></div>
          {/* Mockup image */}
          <img
            src={mockup}
            alt="mockup-image"
            className="w-full sm:w-56 relative transform scale-custom sm:scale-110 z-10"
          />
        </div>
        <div className="self-center mt-8 sm:mt-0 sm:ml-8">
          <div className="text-3xl font-bold pb-3">RESPO</div>
          <div className="w-60 mx-auto sm:mx-0">
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Error,
            reiciendis dolore. Optio maxime, quae aperiam adipisci labore, quas
            dolor placeat minima nam, fugit est delectus provident. Maiores eius
            debitis ullam.
          </div>
          <div>
            <button className="bg-pallete-5 text-white py-2 px-4 mt-5">
              Learn More
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowItWorks;
