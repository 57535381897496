import React from "react";
import { useLocation } from "react-router-dom";

const Header = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  const menuItems = [
    { id: "0", name: "HOME", url: "/" },
    { id: "1", name: "HOW IT WORKS", url: "/how-it-works" },
    { id: "2", name: "FEATURES", url: "/features" },
    { id: "3", name: "ABOUT US", url: "/about-us" },
  ];

  return (
    <div className="mt-4 mb-20">
      <div className="text-white py-2 px-2 md:px-4 rounded-xl">
        <div className="flex justify-between">
          {menuItems.map((item) => (
            <a
              key={item.id}
              href={item.url}
              className={`px-3 md:px-6 py-2 rounded-xl transition-colors duration-300 text-sm text-slate-200 ${
                currentPath === item.url
              }`}
            >
              {item.name}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Header;
