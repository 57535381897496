import { Divider } from "@mui/joy";
import React, { useEffect, useState } from "react";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { MdArrowBackIosNew, MdFlood } from "react-icons/md";
import { GoDotFill } from "react-icons/go";
import { RiEarthquakeFill, RiFireFill } from "react-icons/ri";
import { FaBriefcaseMedical } from "react-icons/fa";
import { HiMiniBellAlert } from "react-icons/hi2";
import { GiNuclearPlant } from "react-icons/gi";
import { BsFillCloudLightningRainFill } from "react-icons/bs";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import io from "socket.io-client";
import { format } from "date-fns";
import notif from "../assets/no-notif.png";
import { DefaultLoader } from "../components/Loader";
import useStore from "../store";
import { IoArrowBack } from "react-icons/io5";
import axios from "axios";

const socket = io(process.env.REACT_APP_API_BASE_URL);

const getIconAndBgColor = (type) => {
  switch (type) {
    case "Typhoon":
      return {
        icon: <MdFlood size={22} />,
        bgColor: "bg-blue-300 text-blue-500",
      };
    case "Earthquake":
      return {
        icon: <RiEarthquakeFill size={22} />,
        bgColor: "bg-stone-300 text-stone-500",
      };
    case "Medical":
      return {
        icon: <FaBriefcaseMedical size={22} />,
        bgColor: "bg-green-300 text-green-500",
      };
    case "Fire":
      return {
        icon: <RiFireFill size={22} />,
        bgColor: "bg-red-300 text-red-500",
      };
    case "Flood":
      return {
        icon: <BsFillCloudLightningRainFill size={22} />,
        bgColor: "bg-blue-300 text-blue-500",
      };
    case "Nuclear":
      return {
        icon: <GiNuclearPlant size={22} />,
        bgColor: "bg-orange-300 text-orange-500",
      };
    case "Thunderstorm":
      return {
        icon: <BsFillCloudLightningRainFill size={22} />,
        bgColor: "bg-purple-300 text-purple-500",
      };
    case "General":
    default:
      return {
        icon: <HiMiniBellAlert size={22} />,
        bgColor: "bg-yellow-300 text-yellow-500",
      };
  }
};

const formatTimestamp = (timestamp) => {
  const now = new Date();
  const date = new Date(timestamp);

  const diffInMinutes = (now - date) / (1000 * 60); // Difference in minutes
  const diffInHours = diffInMinutes / 60; // Difference in hours
  const diffInDays = diffInHours / 24; // Difference in days

  if (diffInDays >= 7) {
    // Display "MMM d, yyyy | h:mm a" for timestamps older than 7 days
    return format(date, "MMM d, yyyy | h:mm a");
  } else if (diffInDays >= 1) {
    // Display "1d ago", "2d ago" for timestamps older than 1 day but less than 7 days
    return `${Math.floor(diffInDays)}d ago`;
  } else if (diffInHours >= 1) {
    // Display "1h ago", "2h ago" for timestamps older than 1 hour but less than 24 hours
    return `${Math.floor(diffInHours)}h ago`;
  } else if (diffInMinutes >= 1) {
    // Display "1m ago", "2m ago" for timestamps older than 1 minute but less than 1 hour
    return `${Math.floor(diffInMinutes)}m ago`;
  } else {
    // Display "Just now" for timestamps within 1 minute
    return "Just now";
  }
};

const UserNotification = () => {
  const { currentUser } = useStore((state) => ({
    currentUser: state.currentUser,
  }));
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    const fetchNotifications = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/server/notif/user-notifs/${currentUser._id}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch notifications");
        }
        const data = await response.json();

        // Filter out any notifications that might be null or undefined
        const validData = data.filter((notif) => notif && notif.alertId);

        // Sort the notifications by timestamp in descending order
        const sortedData = validData.sort(
          (a, b) => new Date(b.alertTimestamp) - new Date(a.alertTimestamp)
        );

        setNotifications(sortedData);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();

    socket.on("get-latest-notif", (notifs) => {
      setNotifications((prevNotifications) => {
        const validNotifs = notifs.filter((notif) => notif && notif.alertId);

        // Sort the notifications by timestamp in descending order
        const sortedNotifs = validNotifs.sort(
          (a, b) => new Date(b.alertTimestamp) - new Date(a.alertTimestamp)
        );

        return [...sortedNotifs];
      });
    });

    socket.on("latest-notif", (notif) => {
      setNotifications((prevNotifications) => {
        if (notif && notif.alertId) {
          const index = prevNotifications.findIndex(
            (n) => n.alertId === notif.alertId
          );

          if (index !== -1) {
            const updatedNotifications = [...prevNotifications];
            updatedNotifications[index] = notif;
            return updatedNotifications.sort(
              (a, b) => new Date(b.alertTimestamp) - new Date(a.alertTimestamp)
            );
          }

          return [notif, ...prevNotifications].sort(
            (a, b) => new Date(b.alertTimestamp) - new Date(a.alertTimestamp)
          );
        }
        return prevNotifications;
      });
    });

    return () => {
      socket.off("get-latest-notif");
      socket.off("latest-notif");
    };
  }, [currentUser]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleNotificationClick = async (notificationId) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/server/notif/read`,
        {
          alertId: notificationId, // Include alert ID
          userId: currentUser._id,
          userName: currentUser.name,
        }
      );

      // Navigate to the notification details page
      navigate(`/user-notification/${notificationId}`);
    } catch (error) {
      console.error("Error updating notification status:", error);
    }
  };

  return (
    <div className="w-full relative">
      <div className="w-full max-w-lg fixed top-0 z-10 bg-white drop-shadow-sm">
        <div className="flex justify-between items-center px-4 py-4">
          <div className="flex font-semibold gap-4">
            <IoArrowBack
              size={20}
              onClick={handleGoBack}
              className="self-center cursor-pointer"
            />
            Notifications
          </div>
          <div>
            <HiOutlineDotsVertical
              onClick={handleClick}
              aria-controls={open ? "details" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              className="self-center cursor-pointer"
              size={20}
            />
          </div>
        </div>
      </div>
      <div className="mt-14">
        {loading ? (
          <div className="flex justify-center items-center h-screen">
            <DefaultLoader />
          </div>
        ) : notifications.length === 0 ? (
          <div className="text-center flex flex-col justify-center py-40 mx-auto items-center overflow-y-hidden">
            <div>
              <img src={notif} width={250} alt="No notifications" />
            </div>
            <p className="text-gray-500 my-4 text-lg">No notifications yet</p>
          </div>
        ) : (
          notifications.map((notification, index) => {
            const { icon, bgColor } = getIconAndBgColor(notification.alertType);
            // Check if the currentUser is in the isUnread array
            const isUnreadByCurrentUser = notification.isUnread.some(
              (user) => user.userId === currentUser._id
            );

            // Check if the currentUser is in the isRead array
            const isReadByCurrentUser = notification.isRead.some(
              (user) => user.userId === currentUser._id
            );
            return (
              <div
                key={`${notification.alertId}-${index}`} // Unique key using both alertId and index
                onClick={() => handleNotificationClick(notification.alertId)}
                className="bg-white"
              >
                <div className="flex p-4 cursor-pointer">
                  <div
                    className={`${bgColor} bg-opacity-20 mr-3 rounded-full p-4 self-start`}
                  >
                    {icon}
                  </div>
                  <div className="w-full">
                    <p className="font-semibold text-sm text-ellipsis overflow-hidden line-clamp-1">
                      {notification.alertTitle}
                    </p>
                    <p className="text-gray-500 text-xs text-ellipsis overflow-hidden max-h-[2.5rem] line-clamp-2">
                      {notification.alertDescription}
                    </p>
                    <p className="text-xs text-gray-400 my-1">
                      {formatTimestamp(notification.alertTimestamp)}
                    </p>
                  </div>
                  {isUnreadByCurrentUser && !isReadByCurrentUser && (
                    <GoDotFill className="text-blue-500 mx-2" size={24} />
                  )}
                </div>
                <Divider />
              </div>
            );
          })
        )}
      </div>
      <div>
        <Menu
          anchorEl={anchorEl}
          id="details"
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onClick={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleClose}>Mark all as read</MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default UserNotification;
