import React, { useState } from "react";
import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import UserContact from "../../pages/UserContact";

const CallContactCallPopup = (props) => {
  const { openCallContactPopup, setOpenCallContactPopup, phone } = props;

  const handleClosePopup = () => {
    setOpenCallContactPopup(false);
  };
  const handleConfirmCall = () => {
    window.location.href = `tel:${phone}`;
    setOpenCallContactPopup(false);
  };

  const isMobile = useMediaQuery("(max-width:600px)");

  const dynamicPopupStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    p: 2,
    width: "min(90%, 490px)",
    maxHeight: isMobile ? "95vh" : "calc(100vh - 100px)",
    overflowY: "auto",
  };

  return (
    <div>
      <Modal open={openCallContactPopup} onClose={handleClosePopup}>
        <Box
          className="bg-white rounded-xl "
          sx={dynamicPopupStyle}
          style={
            isMobile || window.innerWidth <= window.innerHeight * 2
              ? dynamicPopupStyle
              : null
          }
        >
          <div className="flex flex-col justify-center items-center px-1">
            <div className="text-center my-2 font-bold text-2xl">
              Confirm Call{" "}
            </div>
            <div>Are you sure you want to call this number?</div>

            <div className="flex w-full gap-4 mt-8">
              <button
                onClick={handleClosePopup}
                className="p-2 bg-gray-100 rounded-md w-full text-slate-500"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmCall}
                className="p-2 bg-green-400 text-white rounded-md w-full"
              >
                Confirm
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default CallContactCallPopup;
