import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { GoHome } from "react-icons/go";
import { AiOutlineUser } from "react-icons/ai";
import { RiRobot2Line } from "react-icons/ri";
import { IoCallOutline } from "react-icons/io5";
import { AnimatePresence, motion } from "framer-motion";

const menu = [
  { path: "/user-home", icon: <GoHome size={25} />, label: "Home" },
  {
    path: "/user-contact",
    icon: <IoCallOutline size={25} />,
    label: "Contact",
  },
  { path: "/ai-chats", icon: <RiRobot2Line size={25} />, label: "AI Chat" },
  {
    path: "/user-profile",
    icon: <AiOutlineUser size={25} />,
    label: "Profile",
  },
];

const UserNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = (path) => {
    navigate(path);
  };

  // Updated isActive function to include `ai-chats` check
  const isActive = (path) => {
    return (
      location.pathname === path ||
      (path === "/ai-chats" && location.pathname.includes("ai-chats"))
    );
  };
  
  return (
    <motion.div
      className="fixed drop-shadow-xl z-[998] bottom-0 w-full"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      {/* Navigation container */}
      <div className="flex justify-between bg-white p-3 mx-auto max-w-lg">
        <AnimatePresence>
          {menu.map(({ path, icon, label }) => (
            <motion.div
              key={label}
              onClick={() => handleNavigation(path)}
              className={`p-2 rounded-lg cursor-pointer transition-colors duration-300 ${
                isActive(path)
                  ? "bg-gradient-to-r from-blue-300 to-blue-500 text-white"
                  : ""
              }`}
              initial={{ opacity: 0 }}
              animate={{ opacity: isActive(path) ? 1 : 0.7 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.4 }}
            >
              {icon}
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    </motion.div>
  );
};

export default UserNavigation;
