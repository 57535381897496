import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Divider } from "@mui/joy";
import useStore from "../store";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import "react-phone-input-2/lib/bootstrap.css";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { MdOutlineMyLocation } from "react-icons/md";
import map from "../assets/street-map.jpg";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerIconShadow from "leaflet/dist/images/marker-shadow.png";
import regionsDataJson from "../data/region.json";
import provincesDataJson from "../data/province.json";
import citiesDataJson from "../data/city.json";
import barangaysDataJson from "../data/barangay.json";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { app } from "../firebase";
import profile from "../assets/defaultProfile.jfif";

// Custom marker icon
let DefaultIcon = L.icon({
  iconUrl: markerIcon, // Provide your marker icon path here
  shadowUrl: markerIconShadow, // Provide your marker shadow path here
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});
L.Marker.prototype.options.icon = DefaultIcon;

// Component to control popup opening
const MarkerWithPopup = ({ position }) => {
  const map = useMap();

  useEffect(() => {
    const marker = L.marker(position, { icon: DefaultIcon }).addTo(map);
    marker.bindPopup("You are here").openPopup();

    return () => {
      map.removeLayer(marker);
    };
  }, [map, position]);

  return null;
};

const RegistrationAndTerms = () => {
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [isPrivacyChecked, setIsPrivacyChecked] = useState(false);
  // Load selected values from localStorage
  const [selectedRegion, setSelectedRegion] = useState(() => {
    const savedData = JSON.parse(localStorage.getItem("registrationFormData"));
    return savedData ? savedData.addressLocation.region.code : "";
  });
  const [selectedProvince, setSelectedProvince] = useState(() => {
    const savedData = JSON.parse(localStorage.getItem("registrationFormData"));
    return savedData ? savedData.addressLocation.province.code : "";
  });
  const [selectedCity, setSelectedCity] = useState(() => {
    const savedData = JSON.parse(localStorage.getItem("registrationFormData"));
    return savedData ? savedData.addressLocation.city.code : "";
  });
  const [selectedBarangay, setSelectedBarangay] = useState(() => {
    const savedData = JSON.parse(localStorage.getItem("registrationFormData"));
    return savedData ? savedData.addressLocation.barangay.code : "";
  });
  const [selectedRegionName, setSelectedRegionName] = useState(() => {
    const savedData = JSON.parse(localStorage.getItem("registrationFormData"));
    return savedData ? savedData.addressLocation.region.name : "";
  });
  const [selectedProvinceName, setSelectedProvinceName] = useState(() => {
    const savedData = JSON.parse(localStorage.getItem("registrationFormData"));
    return savedData ? savedData.addressLocation.province.name : "";
  });
  const [selectedCityName, setSelectedCityName] = useState(() => {
    const savedData = JSON.parse(localStorage.getItem("registrationFormData"));
    return savedData ? savedData.addressLocation.city.name : "";
  });
  const [selectedBarangayName, setSelectedBarangayName] = useState(() => {
    const savedData = JSON.parse(localStorage.getItem("registrationFormData"));
    return savedData ? savedData.addressLocation.barangay.name : "";
  });

  const [regionsData, setRegionsData] = useState([]);
  const [provincesData, setProvincesData] = useState([]);
  const [citiesData, setCitiesData] = useState([]);
  const [barangaysData, setBarangaysData] = useState([]);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
  });

  const fileRef = useRef(null);

  const [image, setImage] = useState(undefined);
  const [imagePercent, setImagePercent] = useState(0);
  const [imageError, setImageError] = useState(false);
  const [isImageChanged, setIsImageChanged] = useState(false);

  const [formData, setFormData] = useState(() => {
    // Load data from localStorage or initialize with default values
    const savedData = localStorage.getItem("registrationFormData");
    return savedData
      ? JSON.parse(savedData)
      : {
          profilePicture: "",
          fullname: "",
          addressLocation: {
            street: "",
            barangay: { code: "", name: "" },
            city: { code: "", name: "" },
            province: { code: "", name: "" },
            region: { code: "", name: "" },
            latitude: null,
            longitude: null,
          },
          phone: "",
          age: "",
          gender: "",
          password: "",
          confirmPassword: "",
          street: "",
        };
  });

  const [userLocation, setUserLocation] = useState(() => {
    const savedLocation = JSON.parse(localStorage.getItem("userLocation"));
    return savedLocation
      ? { lat: savedLocation.latitude, lng: savedLocation.longitude }
      : null; // Load location from localStorage if available
  });

  const [step, setStep] = useState(() => {
    // Load current step from localStorage or start at step 1
    return parseInt(localStorage.getItem("registrationStep")) || 1;
  });

  const [regError, setRegError] = useState("");
  const [stepErrors, setStepErrors] = useState({
    step1: "",
    step2: "",
    step3: "",
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setUserRegistered, setCurrentUser } = useStore();
  const [isMapVisible, setIsMapVisible] = useState(false);

  useEffect(() => {
    setRegionsData(regionsDataJson);
    setProvincesData(provincesDataJson);
    setCitiesData(citiesDataJson);
    setBarangaysData(barangaysDataJson);

    // Load saved user location from localStorage
    const savedLocation = JSON.parse(localStorage.getItem("userLocation"));
    if (savedLocation) {
      setUserLocation({
        lat: savedLocation.latitude,
        lng: savedLocation.longitude,
      });
      setFormData((prevData) => ({
        ...prevData,
        addressLocation: {
          ...prevData.addressLocation,
          latitude: savedLocation.latitude,
          longitude: savedLocation.longitude,
        },
      }));
      setIsMapVisible(true);
    }
  }, []);

  // Update localStorage whenever formData, userLocation, or step changes
  useEffect(() => {
    localStorage.setItem("registrationFormData", JSON.stringify(formData));
    localStorage.setItem("registrationStep", step.toString());
    if (userLocation) {
      localStorage.setItem(
        "userLocation",
        JSON.stringify({
          latitude: userLocation.lat,
          longitude: userLocation.lng,
        })
      );
    }
  }, [formData, step, userLocation]);

  // Pin location function
  const handlePinLocation = (e) => {
    e.preventDefault();

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const location = { lat: latitude, lng: longitude };
          setUserLocation(location);
          setIsMapVisible(true);

          // Update formData with latitude and longitude
          setFormData((prevData) => ({
            ...prevData,
            addressLocation: {
              ...prevData.addressLocation,
              latitude: latitude,
              longitude: longitude,
            },
          }));

          // Save location to localStorage
          localStorage.setItem(
            "userLocation",
            JSON.stringify({
              latitude,
              longitude,
            })
          );

          // Clear any location-related errors when the user successfully pins the location
          setRegError("");
        },
        (error) => {
          console.error("Error getting location: ", error);
          setRegError(
            "Unable to retrieve your location. Please allow and open your location."
          );
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
      setRegError("Geolocation is not supported by this browser.");
    }
  };

  // Update address location based on selected values
  const updateAddressLocation = () => {
    setFormData((prevData) => ({
      ...prevData,
      addressLocation: {
        street: prevData.street,
        barangay: {
          code: selectedBarangay,
          name: selectedBarangayName,
        },
        city: {
          code: selectedCity,
          name: selectedCityName,
        },
        province: {
          code: selectedProvince,
          name: selectedProvinceName,
        },
        region: {
          code: selectedRegion,
          name: selectedRegionName,
        },
        latitude: prevData.addressLocation.latitude,
        longitude: prevData.addressLocation.longitude,
      },
    }));
  };

  useEffect(() => {
    if (image) {
      handleFileUpload(image);
    }
  }, [image]);

  const handleFileUpload = async (newImage) => {
    const storage = getStorage(app);
    const fileName = new Date().getTime() + newImage.name;
    const storageRef = ref(storage, fileName);
    const uploadTask = uploadBytesResumable(storageRef, newImage);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setImagePercent(Math.round(progress));
      },
      () => {
        setImageError(true);
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          setFormData({ ...formData, profilePicture: downloadURL });
          setIsImageChanged(true); // Set isImageChanged to true when the image is uploaded
        } catch (error) {
          console.error("Error updating profile picture: ", error);
        }
      }
    );
  };

  const handleRegionChange = (event) => {
    const region = regionsData.find(
      (r) => r.region_code === event.target.value
    );
    if (region) {
      setSelectedRegion(region.region_code);
      setSelectedRegionName(region.region_name);

      // Filter provinces based on selected region
      const filteredProvinces = provincesDataJson.filter(
        (p) => p.region_code === region.region_code
      );
      setProvincesData(filteredProvinces);
      setSelectedProvince(""); // Reset province selection
      setCitiesData([]); // Clear city selection
      setBarangaysData([]); // Clear barangay selection
      updateAddressLocation();
    }
  };

  const handleProvinceChange = (event) => {
    const province = provincesData.find(
      (p) => p.province_code === event.target.value
    );
    if (province) {
      setSelectedProvince(province.province_code);
      setSelectedProvinceName(province.province_name);

      // Filter cities based on selected province
      const filteredCities = citiesDataJson.filter(
        (c) => c.province_code === province.province_code
      );
      setCitiesData(filteredCities);
      setSelectedCity(""); // Reset city selection
      setBarangaysData([]); // Clear barangay selection
      updateAddressLocation();
    }
  };

  const handleCityChange = (event) => {
    const city = citiesData.find((c) => c.city_code === event.target.value);
    if (city) {
      setSelectedCity(city.city_code);
      setSelectedCityName(city.city_name);

      // Filter barangays based on selected city
      const filteredBarangays = barangaysDataJson.filter(
        (b) => b.city_code === city.city_code
      );
      setBarangaysData(filteredBarangays);
      setSelectedBarangay(""); // Reset barangay selection
      updateAddressLocation();
    }
  };

  const handleBarangayChange = (event) => {
    const barangay = barangaysData.find(
      (b) => b.brgy_code === event.target.value
    );
    if (barangay) {
      setSelectedBarangay(barangay.brgy_code);
      setSelectedBarangayName(barangay.brgy_name);
      updateAddressLocation();
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [id]: value }));

    if (id === "password") {
      // Update the criteria based on the password input
      setPasswordCriteria({
        length: value.length >= 8,
        uppercase: /[A-Z]/.test(value),
        lowercase: /[a-z]/.test(value),
        number: /\d/.test(value),
        specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(value),
      });
    }
    // Clear the step error if the input is related to that step
    if (
      step === 1 &&
      ["profile", "fullname", "phone", "age", "gender"].includes(id)
    ) {
      setStepErrors((prev) => ({ ...prev, step1: "" }));
    } else if (step === 2 && ["street"].includes(id)) {
      setStepErrors((prev) => ({ ...prev, step2: "" }));
      updateAddressLocation();
    } else if (step === 3 && ["password", "confirmPassword"].includes(id)) {
      setStepErrors((prev) => ({ ...prev, step3: "" })); // Clear error for step 3
    }

    setRegError(""); // Clear any registration errors
  };

  const handleRegister = (e) => {
    e.preventDefault();
    setLoading(true);

    // Regular expression to check password requirements
    const passwordValidationRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

    // Reset previous step error when proceeding to the next step
    if (step === 1) {
      // Clear previous step errors
      setStepErrors((prev) => ({
        ...prev,
        fullname: "",
        phone: "",
        age: "",
        gender: "",
        profilePicture: "",
      }));

      let errors = {};
      let emptyFields = [];

      if (!formData.profilePicture) {
        emptyFields.push("profilePicture");
        errors.profilePicture = "Please upload a profile picture";
      }

      if (!formData.fullname) {
        emptyFields.push("fullname");
        errors.fullname = "Please put your name";
      }

      if (!formData.phone) {
        emptyFields.push("phone");
        errors.phone = "Please provide your phone number";
      }

      if (!formData.age) {
        emptyFields.push("age");
        errors.age = "Please provide your age";
      }

      if (!formData.gender) {
        emptyFields.push("gender");
        errors.gender = "Please select your gender";
      }

      // Generate custom error message based on empty fields
      if (emptyFields.length === 5) {
        setStepErrors((prev) => ({
          ...prev,
          step1: "All fields must not be empty",
        }));
      } else if (
        emptyFields.includes("fullname") &&
        emptyFields.includes("age") &&
        emptyFields.includes("phone")
      ) {
        setStepErrors((prev) => ({
          ...prev,
          step1: "Please provide your name, age, and phone number",
        }));
      } else if (Object.keys(errors).length > 0) {
        // Set individual field errors
        setStepErrors((prev) => ({
          ...prev,
          ...errors, // Merge the specific field errors
        }));
      }

      // If any errors exist, stop and show them
      if (Object.keys(errors).length > 0 || emptyFields.length > 0) {
        setLoading(false);
        return;
      }

      // Validate phone number (must be exactly 11 digits and contain only numbers)
      if (!/^\d{11}$/.test(formData.phone)) {
        setStepErrors((prev) => ({
          ...prev,
          phone:
            "Phone number must be exactly 11 digits and contain only numbers",
        }));
        setLoading(false);
        return;
      }

      setStep(2);
    } else if (step === 2) {
      setStepErrors((prev) => ({ ...prev, step2: "" }));

      if (
        !formData.addressLocation.street ||
        !formData.addressLocation.barangay.code ||
        !formData.addressLocation.city.code ||
        !formData.addressLocation.province.code ||
        !formData.addressLocation.region.code ||
        !formData.addressLocation.latitude ||
        !formData.addressLocation.longitude
      ) {
        setStepErrors((prev) => ({
          ...prev,
          step2: "Please fill out your complete address and pin your location",
        }));
        setLoading(false);
        return;
      }

      setStep(3);
    } else if (step === 3) {
      setStepErrors((prev) => ({ ...prev, step3: "" }));

      // Password validation
      if (!formData.password || !formData.confirmPassword) {
        setStepErrors((prev) => ({
          ...prev,
          step3: "Please set a password and confirm password",
        }));
        setLoading(false);
        return;
      }

      // Check if password meets criteria
      if (!passwordValidationRegex.test(formData.password)) {
        setStepErrors((prev) => ({
          ...prev,
          step3:
            "Password must be at least 8 characters long, include at least one number, one lowercase letter, and one uppercase letter.",
        }));
        setLoading(false);
        return;
      }

      // Check if passwords match
      if (formData.password !== formData.confirmPassword) {
        setStepErrors((prev) => ({ ...prev, step3: "Passwords do not match" }));
        setLoading(false);
        return;
      }

      setStep(4);
    }
    setLoading(false);
  };

  const handleConfirm = async (e) => {
    e.preventDefault();
    if (isTermsChecked && isPrivacyChecked) {
      const requiredFields = [
        formData.profilePicture,
        formData.fullname,
        formData.phone,
        formData.age,
        formData.gender,
        formData.password,
        formData.confirmPassword,
      ];

      if (requiredFields.some((field) => !field)) {
        setRegError("All fields and terms acceptance are required");
        return;
      }

      try {
        const formattedData = {
          ...formData,
          isTermsChecked,
          isPrivacyChecked,
        };

        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/server/registration/register-and-confirm`,
          formattedData,
          { headers: { "Content-Type": "application/json" } }
        );
        localStorage.removeItem("registrationFormData");
        localStorage.removeItem("registrationStep");
        localStorage.removeItem("userLocation");

        localStorage.setItem("userRegistered", "true");
        localStorage.setItem("access_token", response.data.access_token);
        setUserRegistered(true);
        setCurrentUser(response.data.citizen);
        navigate("/user-home");
      } catch (error) {
        console.error(
          "An error occurred:",
          error.response?.data?.message || "An error occurred"
        );
        setRegError(error.response?.data?.message || "An error occurred");
      }
    } else {
      setRegError(
        "Please agree to both terms and conditions and privacy policy."
      );
    }
  };

  const goBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  return (
    <div className="mt-4 max-w-lg mx-auto px-8 py-2">
      <div className="text-center mb-8">
        <div className="text-center">
          <h1 className="font-semibold text-3xl mb-2">Register Now</h1>
        </div>
        <p className="text-sm">Complete your registration</p>
        <p className="text-sm">Step {step} of 4</p>
        <div className="flex gap-1 my-2">
          {[...Array(4)].map((_, index) => (
            <div
              key={index}
              className={`w-1/4 h-1.5 ${
                step === index + 1 ? "bg-blue-300" : "bg-gray-200"
              }`}
            ></div>
          ))}
        </div>
      </div>
      {step === 1 && (
        <div>
          <Divider />
          <form className="my-4 w-full">
            {/* Combined error for multiple empty fields */}
            {stepErrors.step1 && (
              <p className="text-red-500 bg-red-100 p-2 rounded-md text-center text-sm">
                {stepErrors.step1}
              </p>
            )}

            {/* Full Name Error */}
            {stepErrors.fullname && !stepErrors.step1 && (
              <p className="text-red-500 bg-red-100 p-2 rounded-md text-center text-sm">
                {stepErrors.fullname}
              </p>
            )}

            {/* Phone Number Error */}
            {stepErrors.phone && !stepErrors.step1 && (
              <p className="text-red-500 bg-red-100 p-2 rounded-md text-center text-sm">
                {stepErrors.phone}
              </p>
            )}

            {/* Age Error */}
            {stepErrors.age && !stepErrors.step1 && (
              <p className="text-red-500 bg-red-100 p-2 rounded-md text-center text-sm">
                {stepErrors.age}
              </p>
            )}

            {/* Gender Error */}
            {stepErrors.gender && !stepErrors.step1 && (
              <p className="text-red-500 bg-red-100 p-2 rounded-md text-center text-sm">
                {stepErrors.gender}
              </p>
            )}

            {/* Profile Picture Error */}
            {stepErrors.profilePicture && !stepErrors.step1 && (
              <p className="text-red-500 bg-red-100 p-2 rounded-md text-center text-sm">
                {stepErrors.profilePicture}
              </p>
            )}
            <div className="my-2">
              <input
                type="file"
                id="profile"
                ref={fileRef}
                hidden
                accept="image/*"
                onChange={(e) => setImage(e.target.files[0])}
              />
              <div className="flex flex-col gap-3 justify-center items-center">
                <img
                  src={
                    image
                      ? URL.createObjectURL(image)
                      : formData.profilePicture
                      ? formData.profilePicture
                      : profile
                  }
                  alt="profile"
                  className="flex items-center w-32 h-32 rounded-full object-cover mx-auto"
                  key={image ? image.name : formData.profilePicture}
                />
                <button
                  type="button"
                  onClick={() => fileRef.current.click()}
                  className="p-2 bg-blue-500 text-white rounded-lg text-sm"
                >
                  Upload Your Photo
                </button>
              </div>
              <div className="text-sm self-center m-4 text-center">
                {imageError ? (
                  <span className="text-red-500">
                    Error uploading image (file size must be less than 3 MB)
                  </span>
                ) : imagePercent > 0 && imagePercent < 100 ? (
                  <span className="text-slate-700 dark:text-gray-200">
                    Uploading: {imagePercent}%
                    <span
                      className="block h-1 bg-blue-500"
                      style={{
                        width: `${imagePercent}%`,
                        transition: "width 0.3s ease-out",
                      }}
                    />
                  </span>
                ) : imagePercent === 100 &&
                  image &&
                  image.size <= 3 * 1024 * 1024 ? (
                  <span className="text-green-500 text-sm">
                    Image uploaded successfully
                  </span>
                ) : null}
              </div>
            </div>
            <div className="my-2">
              <p className="text-sm mb-1">Fullname</p>
              <input
                type="text"
                id="fullname"
                value={formData.fullname}
                onChange={handleChange}
                className="form-control p-2"
              />
            </div>
            <div className="my-2 w-full">
              <p className="text-sm mb-1">Contact Number</p>
              <div className="w-full flex-1">
                <input
                  type="tel"
                  id="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="form-control p-2"
                  required
                  minLength="11"
                  maxLength="11"
                  pattern="\d{11}"
                  inputMode="numeric"
                />
              </div>
            </div>
            <div className="my-2">
              <p className="text-sm mb-1">Age</p>
              <input
                type="number" // Changed to number type
                id="age"
                value={formData.age}
                onChange={(e) => {
                  const value = e.target.value;

                  // Allow only numbers
                  if (/^\d*$/.test(value)) {
                    handleChange(e);
                  }
                }}
                className="form-control p-2"
              />
            </div>

            <div>
              <p className="text-sm mb-1">Select your gender</p>
              <div className="w-full bg-white py-1 px-2 form-control">
                <FormControl>
                  <RadioGroup
                    row
                    value={formData.gender}
                    onChange={(e) =>
                      setFormData({ ...formData, gender: e.target.value })
                    }
                    className="flex w-full"
                  >
                    <FormControlLabel
                      value="female"
                      control={<Radio />}
                      label="Female"
                      className="flex-1"
                    />
                    <FormControlLabel
                      value="male"
                      control={<Radio />}
                      label="Male"
                      className="flex-1"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <button
              onClick={handleRegister}
              className="bg-blue-500 p-2 w-full rounded-md text-white mt-4 hover:opacity-70"
              disabled={loading}
            >
              {loading ? "Loading..." : "Next"}
            </button>
          </form>
        </div>
      )}
      {step === 2 && (
        <div>
          <Divider />
          <form className="my-4 w-full">
            {stepErrors.step2 && (
              <p className="text-red-500 bg-red-100 p-2 rounded-md text-center text-sm mb-4">
                {stepErrors.step2}
              </p>
            )}
            {/* Philippine Address Selectors */}
            <div className="w-full mb-4">
              <FormControl
                className="w-full bg-white rounded-md form-control"
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
              >
                <Select
                  value={selectedRegion}
                  onChange={handleRegionChange}
                  input={<OutlinedInput placeholder="Select Region" />}
                  displayEmpty
                >
                  <MenuItem value="">
                    <p>Select Region</p>
                  </MenuItem>
                  {regionsData.map((region) => (
                    <MenuItem
                      key={region.region_code}
                      value={region.region_code}
                    >
                      {region.region_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="w-full mb-4">
              <FormControl
                className="w-full bg-white rounded-md form-control"
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                disabled={!selectedRegion}
              >
                <Select
                  value={selectedProvince}
                  onChange={handleProvinceChange}
                  input={<OutlinedInput placeholder="Select Province" />}
                  displayEmpty
                >
                  <MenuItem value="">
                    <p>Select Province</p>
                  </MenuItem>
                  {provincesData.map((province) => (
                    <MenuItem
                      key={province.province_code}
                      value={province.province_code}
                    >
                      {province.province_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="w-full mb-4">
              <FormControl
                className="w-full bg-white rounded-md form-control"
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                disabled={!selectedProvince}
              >
                <Select
                  value={selectedCity}
                  onChange={handleCityChange}
                  input={<OutlinedInput placeholder="Select City" />}
                  displayEmpty
                >
                  <MenuItem value="">
                    <p>Select City</p>
                  </MenuItem>
                  {citiesData.map((city) => (
                    <MenuItem key={city.city_code} value={city.city_code}>
                      {city.city_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="w-full mb-4">
              <FormControl
                className="w-full bg-white border border-gray-300 rounded-md form-control"
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                disabled={!selectedCity}
              >
                <Select
                  value={selectedBarangay}
                  onChange={handleBarangayChange}
                  input={<OutlinedInput placeholder="Select Barangay" />}
                  displayEmpty
                >
                  <MenuItem value="">
                    <p>Select Barangay</p>
                  </MenuItem>
                  {barangaysData.map((barangay) => (
                    <MenuItem
                      key={barangay.brgy_code}
                      value={barangay.brgy_code}
                    >
                      {barangay.brgy_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="my-2">
              <input
                type="text"
                id="street"
                placeholder="Street Name, Building, House No."
                value={formData.street}
                onChange={handleChange}
                className="form-control p-4 text-sm"
              />
            </div>

            <div className="my-6">
              {!isMapVisible && (
                <div
                  className="mt-6 bg-cover bg-center bg-no-repeat w-full h-44 rounded-lg"
                  style={{ backgroundImage: `url(${map})` }}
                >
                  <div className="flex justify-center items-center h-full bg-white/60 rounded-lg">
                    <button
                      onClick={handlePinLocation}
                      className="flex gap-2 p-2 border rounded-md text-sm items-center text-slate-600 bg-white"
                    >
                      <MdOutlineMyLocation /> Pin my location
                    </button>
                  </div>
                </div>
              )}

              {isMapVisible && userLocation && (
                <div className="mt-6 w-full h-44">
                  <MapContainer
                    center={[userLocation.lat, userLocation.lng]}
                    zoom={14}
                    className="w-full h-full rounded-lg"
                  >
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    <MarkerWithPopup
                      position={[userLocation.lat, userLocation.lng]}
                    />
                  </MapContainer>
                </div>
              )}
            </div>

            <button
              onClick={handleRegister}
              className="bg-blue-500 p-2 w-full rounded-md text-white mt-4 hover:opacity-70"
              disabled={loading}
            >
              {loading ? "Loading..." : "Next"}
            </button>
            <button
              onClick={goBack}
              className="mt-2 text-blue-500 hover:text-blue-700"
            >
              Go Back
            </button>
          </form>
        </div>
      )}

      {step === 3 && (
        <div>
          <Divider />
          <form className="my-4 w-full">
            {stepErrors.step3 && (
              <p className="text-red-500 bg-red-100 p-2 rounded-md text-center text-sm">
                {stepErrors.step3}
              </p>
            )}
            <div className="mt-2 mb-6 text-sm ">
              <p>Make your password strong:</p>
              <ul className="list-disc list-inside space-y-1">
                <li
                  className={
                    passwordCriteria.uppercase
                      ? "text-green-500 font-semibold"
                      : "text-gray-500"
                  }
                >
                  At least one uppercase letter
                </li>
                <li
                  className={
                    passwordCriteria.lowercase
                      ? "text-green-500 font-semibold"
                      : "text-gray-500"
                  }
                >
                  At least one lowercase letter
                </li>
                <li
                  className={
                    passwordCriteria.number
                      ? "text-green-500 font-semibold"
                      : "text-gray-500"
                  }
                >
                  At least one number
                </li>
                <li
                  className={
                    passwordCriteria.specialChar
                      ? "text-green-500 font-semibold"
                      : "text-gray-500"
                  }
                >
                  At least one special character
                </li>
                <li
                  className={
                    passwordCriteria.length
                      ? "text-green-500 font-semibold"
                      : "text-gray-500"
                  }
                >
                  Minimum 8 characters
                </li>
              </ul>
            </div>

            <div className="my-2 ">
              <p className="text-sm mb-1">Set your password</p>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  value={formData.password}
                  onChange={handleChange}
                  className="form-control p-2 pr-10"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-2 flex items-center pr-2 cursor-pointer text-gray-400 "
                >
                  {showPassword ? (
                    <AiFillEyeInvisible size={23} />
                  ) : (
                    <AiFillEye size={23} />
                  )}
                </button>
              </div>
            </div>

            <div className="my-2">
              <p className="text-sm mb-1">Confirm password</p>
              <div className="relative">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  id="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  className="form-control p-2 pr-10" // Padding to the right for the icon
                />
                <button
                  type="button"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  className="absolute  inset-y-0 right-2 flex items-center pr-2 cursor-pointer text-gray-400 "
                >
                  {showConfirmPassword ? (
                    <AiFillEyeInvisible size={23} />
                  ) : (
                    <AiFillEye size={23} />
                  )}
                </button>
              </div>
            </div>

            <button
              onClick={handleRegister}
              className="bg-blue-500 p-2 w-full rounded-md text-white mt-4 hover:opacity-70"
              disabled={loading}
            >
              {loading ? "Loading..." : "Next"}
            </button>
            <button
              onClick={goBack}
              className="mt-2 text-blue-500 hover:text-blue-700"
            >
              Go Back
            </button>
          </form>
        </div>
      )}
      {step === 4 && (
        <div>
          {regError && (
            <p className="text-red-500 bg-red-100 p-2 rounded-md text-center text-sm">
              {regError}
            </p>
          )}
          <Divider />
          <form className="my-4 w-full">
            <div className="uppercase text-2xl font-semibold">
              Terms and Conditions
            </div>
            <p className="text-sm font-semibold text-gray-600 mb-4">
              Last update on August 2024
            </p>
            <Divider />
            <div className="my-4 text-justify">
              {/* Terms and conditions text */}
              <p className="font-semibold">AGREEMENT TO OUR LEGAL TERMS</p>
              <p className="mt-2 text-sm">
                We are [Company Name], doing business as [Company Short Name]
                ("Company," “we,” “us,” or “our”), a company registered in
                [Registered Country] at [Registered Address]. [Our VAT number is
                [Number].]
              </p>
              <p className="mt-2 text-sm">
                We operate the website [Website URL] (the “Site”)[, the mobile
                application [Mobile App Name] (the “App”)], as well as any other
                related products and services that refer or link to these legal
                terms (the “Legal Terms”) (collectively, the “Services”).
              </p>
              <p className="mt-2 text-sm">
                You can contact us by phone at [Phone Number], email at [Email
                Address], or by mail to [Company Address].
              </p>
              <p className="mt-2 text-sm">
                These Legal Terms constitute a legally binding agreement made
                between you, whether personally or on behalf of an entity
                (“you”), and [Company Name], and concerning your access to and
                use of the Services. You agree that by accessing the Services,
                you have read, understood, and agreed to be bound by all of
                these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL
                TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES
                AND YOU MUST DISCONTINUE USE IMMEDIATELY.
              </p>
              <p className="mt-2 text-sm">
                These Legal Terms constitute a legally binding agreement made
                between you, whether personally or on behalf of an entity
                (“you”), and [Company Name], and concerning your access to and
                use of the Services. You agree that by accessing the Services,
                you have read, understood, and agreed to be bound by all of
                these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL
                TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES
                AND YOU MUST DISCONTINUE USE IMMEDIATELY.
              </p>
            </div>
            <div className="flex items-center mb-2">
              <input
                type="checkbox"
                id="terms"
                checked={isTermsChecked}
                onChange={(e) => setIsTermsChecked(e.target.checked)}
                className="mr-2"
              />
              <label htmlFor="terms" className="text-sm">
                I accept the terms and conditions
              </label>
            </div>
            <div className="flex items-center mb-2">
              <input
                type="checkbox"
                id="privacy"
                checked={isPrivacyChecked}
                onChange={(e) => setIsPrivacyChecked(e.target.checked)}
                className="mr-2"
              />
              <label htmlFor="privacy" className="text-sm">
                I accept the privacy policy
              </label>
            </div>
            <button
              onClick={handleConfirm}
              className="bg-blue-500 p-2 w-full rounded-md text-white mt-4 hover:opacity-70"
              disabled={loading}
            >
              {loading ? "Loading..." : "Confirm Registration"}
            </button>
            <button
              onClick={goBack}
              className="mt-2 text-blue-500 hover:text-blue-700"
            >
              Go Back
            </button>
          </form>
        </div>
      )}
      <div className="text-center">
        Already have an account ?{" "}
        <Link to="/login">
          <span className="text-blue-500">Login</span>
        </Link>
      </div>
    </div>
  );
};

export default RegistrationAndTerms;
