import { useState, useEffect } from "react";
import { MdOutlineMyLocation } from "react-icons/md";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerIconShadow from "leaflet/dist/images/marker-shadow.png";
import map from "../assets/street-map.jpg";

// Custom marker icon
let DefaultIcon = L.icon({
  iconUrl: markerIcon,
  shadowUrl: markerIconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});
L.Marker.prototype.options.icon = DefaultIcon;

const MarkerWithPopup = ({ position }) => {
  const map = useMap();

  useEffect(() => {
    const marker = L.marker(position, { icon: DefaultIcon }).addTo(map);
    marker.bindPopup("You are here").openPopup();

    return () => {
      map.removeLayer(marker);
    };
  }, [map, position]);

  return null;
};

const PinLocation = ({ onLocationChange, reset }) => {
  const [userLocation, setUserLocation] = useState(null);
  const [isMapVisible, setIsMapVisible] = useState(false);

  const handlePinLocation = (e) => {
    e.preventDefault();

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ lat: latitude, lng: longitude });
          onLocationChange(latitude, longitude);
          setIsMapVisible(true);
        },
        (error) => {
          console.error("Error retrieving location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    if (reset) {
      setUserLocation(null);
      setIsMapVisible(false);
    }
  }, [reset]);

  return (
    <div>
      {!isMapVisible && (
        // Default display
        <div
          className="mt-6 bg-cover bg-center bg-no-repeat w-full h-44 rounded-lg"
          style={{ backgroundImage: `url(${map})` }}
        >
          <div className="flex justify-center items-center h-full bg-white/60 rounded-lg">
            <button
              onClick={handlePinLocation}
              className="flex gap-2 p-2 border rounded-md text-sm items-center text-slate-600 bg-white"
            >
              <MdOutlineMyLocation /> Pin my location
            </button>
          </div>
        </div>
      )}

      {isMapVisible && userLocation && (
        <div className="mt-6 w-full h-44">
          <MapContainer
            center={[userLocation.lat, userLocation.lng]}
            zoom={14}
            className="w-full h-full rounded-lg"
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <MarkerWithPopup position={[userLocation.lat, userLocation.lng]} />
          </MapContainer>
        </div>
      )}
    </div>
  );
};

export default PinLocation;
