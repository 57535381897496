import React, { useState } from "react";
import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const AddContactsPopup = (props) => {
  const { openAddContactPopup, setOpenAddContactPopup } = props;
  const [newContact, setNewContact] = useState({
    name: "",
    phone: "",
    category: "Fire",
  });

  const [contacts, setContacts] = useState([
    { id: 1, name: "Daryl Hans", phone: "09057942955", category: "Fire" },
    { id: 2, name: "Charles Kevin", phone: "09425632145", category: "Fire" },
    { id: 3, name: "John Ryan", phone: "09853621454", category: "Health" },
    { id: 4, name: "Kyle Francis", phone: "09548741236", category: "Health" },
    { id: 5, name: "Diofiel Char", phone: "09745236589", category: "Flood" },
    { id: 6, name: "James Balicoco", phone: "09421563264", category: "Flood" },
    {
      id: 7,
      name: "Mark Daniel",
      phone: "09425698541",
      category: "Earthquake",
    },
    {
      id: 8,
      name: "Julian Jules",
      phone: "091452365239",
      category: "Earthquake",
    },
    { id: 9, name: "Stephen Curry", phone: "09145236984", category: "Crime" },
    { id: 10, name: "Klay Thompson", phone: "09632598952", category: "Crime" },
    {
      id: 11,
      name: "Tom Holland",
      phone: "09452136985",
      category: "Traffic Accidents",
    },
    {
      id: 12,
      name: "Lebron James",
      phone: "09365125478",
      category: "Traffic Accidents",
    },
  ]);

  const handleClosePopup = () => {
    setOpenAddContactPopup(false);
  };

  // Handle form input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewContact((prev) => ({ ...prev, [name]: value }));
  };

  // Handle form submission
  const handleFormSubmit = (e) => {
    e.preventDefault();
    setContacts((prev) => [
      ...prev,
      { ...newContact, id: prev.length + 1 }, // Assuming ID is just incremented
    ]);
    setOpenAddContactPopup(false);
    setNewContact({ name: "", phone: "", category: "Fire" }); // Reset form
  };

  

  const isMobile = useMediaQuery("(max-width:600px)");

  const dynamicPopupStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    p: 2,
    width: "min(90%, 490px)",
    maxHeight: isMobile ? "95vh" : "calc(100vh - 100px)",
    overflowY: "auto",
  };
  return (
    <Modal open={openAddContactPopup} onClose={handleClosePopup}>
      <Box
        className="bg-white rounded-xl "
        sx={dynamicPopupStyle}
        style={
          isMobile || window.innerWidth <= window.innerHeight * 2
            ? dynamicPopupStyle
            : null
        }
      >
        <div>
          Add contact
          <form onSubmit={handleFormSubmit}>
            <div className="mb-4">
              <label className="block text-sm font-medium mb-1" htmlFor="name">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={newContact.name}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium mb-1" htmlFor="phone">
                Phone
              </label>
              <input
                type="text"
                id="phone"
                name="phone"
                value={newContact.phone}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div className="flex justify-end space-x-2">
              <button
                type="button"
                className="bg-gray-300 text-black p-2 rounded"
                onClick={() => setOpenAddContactPopup(false)}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-blue-500 text-white p-2 rounded"
              >
                Add Contact
              </button>
            </div>
          </form>
        </div>
      </Box>
    </Modal>
  );
};

export default AddContactsPopup;
