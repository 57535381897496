import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import useStore from "../store";
import HowItWorks from "../components/HowItWorks";
import Features from "../components/Features";
import AboutUs from "../components/AboutUs";
import mockup from "../assets/mockup.png";

const Home = () => {
  const { isAppInstalled, setAppInstalled, setUserRegistered } = useStore(
    (state) => ({
      isAppInstalled: state.isAppInstalled,
      setAppInstalled: state.setAppInstalled,
      setUserRegistered: state.setUserRegistered,
    })
  );
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setAppInstalled(false);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, [setAppInstalled]);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      if (outcome === "accepted") {
        setAppInstalled(true);
      }
      setDeferredPrompt(null);
    }
  };

  const handleRegisterClick = () => {
    setUserRegistered(true);
    navigate("/user-home"); // Redirect to UserHome after registration
  };

  return (
    <div id="home" className="relative flex flex-col min-h-screen bg-blue-100">
      <div className="text-center w-full bg-pallete-3 pb-72 flex-grow">
        <div className="flex justify-center">
          <Header />
        </div>
        <div className="w-full my-10 text-white">
          <div className="text-6xl lg:text-8xl font-bold tracking-tight lg:tracking-[1rem] sm:tracking-[0.5rem] break-words text-slate-100">
            RESPO
          </div>
          <div className="text-xl lg:text-2xl font-semibold text-slate-200 mt-2">
            Resilient Emergency Support and Preparedness Operations
          </div>
          <p className="mx-4 md:mx-auto max-w-3xl text-sm text-gray-300 my-4 ">
            RESPO is your go-to app for real-time emergency alerts, disaster
            preparedness, and support. Designed to keep you informed and safe,
            RESPO provides instant access to critical updates, interactive AI
            assistance, and vital disaster tips. Empowering residents and local
            officials with the tools needed for effective emergency management,
            RESPO ensures that you stay connected and prepared for any
            situation.
          </p>
          <div className="flex justify-center gap-4 mt-8 text-slate-700">
            <button className="bg-slate-100 py-2 px-3 rounded-full">
              How it works
            </button>
            {!isAppInstalled && (
              <button
                onClick={handleInstallClick}
                className="py-2 px-3 rounded-full bg-slate-100"
              >
                Install app
              </button>
            )}
          </div>
        </div>
      </div>
      {/* Floating mockup images */}
      <div className="absolute inset-x-0 flex justify-center items-center transform z-10 top-[13%] md:top-[14%] lg:top-[14%]">
        <div className="flex justify-center items-center gap-4 md:gap-6 lg:gap-8">
          <img
            src={mockup}
            alt="mock-up"
            className="w-24 sm:w-32 md:w-36 lg:w-44 opacity-90"
          />
          <img
            src={mockup}
            alt="mock-up"
            className="w-28 sm:w-36 md:w-40 lg:w-48 transform scale-110"
          />
          <img
            src={mockup}
            alt="mock-up"
            className="w-24 sm:w-32 md:w-36 lg:w-44 opacity-90"
          />
        </div>
      </div>

      <div>
        <HowItWorks />
      </div>
      <div>
        <Features />
      </div>
      <div>
        <AboutUs />
      </div>
    </div>
  );
};

export default Home;
