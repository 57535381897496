import React, { useState, useEffect } from "react";
import { MdTaskAlt } from "react-icons/md";

function AboutUs() {
  const [iconSize, setIconSize] = useState(24);

  // Set the icon size based on the window width
  useEffect(() => {
    const updateIconSize = () => {
      if (window.innerWidth >= 768) {
        setIconSize(30); // size for medium and above screens
      } else {
        setIconSize(24); // size for small screens
      }
    };

    window.addEventListener("resize", updateIconSize);
    updateIconSize(); // call once to set the initial value

    return () => {
      window.removeEventListener("resize", updateIconSize);
    };
  }, []);

  return (
    <div className="w-full max-w-7xl mx-auto px-4 md:px-8 lg:px-12">
      <div className="text-4xl md:text-5xl lg:text-6xl text-slate-800 text-center font-bold mt-16 md:mt-24 lg:mt-32 border-b-2 border-slate-400 pb-4">
        About Us
      </div>
      <div className="flex flex-col md:flex-row justify-around border rounded-lg bg-pallete-3 mt-8 md:mt-10 p-4 md:p-8 text-white mb-10">
        <div className="text-start md:w-1/2 lg:w-2/5 pt-4">
          <div className="text-5xl md:text-6xl font-bold tracking-wide md:tracking-widest">
            RESPO
          </div>
          <div className="text-lg md:text-xl font-semibold mt-2 text-slate-50">
            Resilient Emergency Support <br />
            and Preparedness Operations
          </div>
          <div className="mt-3 text-slate-200 text-sm md:text-base">
            RESPO is your go-to app for real-time emergency alerts, disaster
            preparedness, and support.
          </div>
          <button className="border bg-slate-100 text-slate-700 mt-5 p-2 hover:bg-slate-200 transition duration-300">
            View Our Work
          </button>
        </div>

        <div className="text-start md:w-1/2 lg:w-2/5 md:pt-0">
          <div className="text-2xl md:text-3xl font-bold pb-5 pt-4">
            What We Do
          </div>
          <div className="flex text-base md:text-lg pb-2 text-slate-200 gap-2">
            <MdTaskAlt className="self-center" size={iconSize} /> AI Chat
            Assistants
          </div>
          <div className="flex text-base md:text-lg pb-2 text-slate-200 gap-2">
            <MdTaskAlt className="self-center" size={iconSize} /> Real-Time
            Emergency Alerts
          </div>
          <div className="flex text-base md:text-lg pb-2 text-slate-200 gap-2">
            <MdTaskAlt className="self-center" size={iconSize} /> Comprehensive
            Community Portal
          </div>
          <div className="flex text-base md:text-lg pb-2 text-slate-200 gap-2">
            <MdTaskAlt className="self-center" size={iconSize} /> GIS-Integrated
            Emergency Operations
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
