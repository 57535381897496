import { create } from "zustand";

const useStore = create((set) => ({
  isAppInstalled: localStorage.getItem("appInstalled") === "true",
  isUserRegistered: localStorage.getItem("userRegistered") === "true",
  currentUser: JSON.parse(localStorage.getItem("currentUser")) || null,
  isEmergencyPopupOpen:
    JSON.parse(localStorage.getItem("isEmergencyPopupOpen")) || false,
  setAppInstalled: (value) => {
    localStorage.setItem("appInstalled", value ? "true" : "false");
    set({ isAppInstalled: value });
  },
  setUserRegistered: (value) => {
    localStorage.setItem("userRegistered", value ? "true" : "false");
    set({ isUserRegistered: value });
  },
  setCurrentUser: (user) => {
    localStorage.setItem("currentUser", JSON.stringify(user));
    set({ currentUser: user });
  },
  setEmergencyPopupState: (isOpen) => {
    localStorage.setItem("isEmergencyPopupOpen", JSON.stringify(isOpen));
    set({ isEmergencyPopupOpen: isOpen });
  },
}));

export default useStore;
