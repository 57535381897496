import { create } from "zustand";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import "./App.css";
import useStore from "./store";
import Home from "./pages/Home";
import RegistrationAndTerms from "./pages/RegistrationAndTerms";
import UserHome from "./pages/UserHome";
import UserProfile from "./pages/UserProfile";
import PrivateRoute from "./components/PrivateRoute";
import UserNotification from "./pages/UserNotification";
import UserContact from "./pages/UserContact";
import ViewUserNotif from "./pages/ViewUserNotif";
import AIChats from "./pages/AIChatBot/AIChats";
import UserLocation from "./pages/UserLocation";
import Login from "./pages/Login";
import ChatLayout from "./components/AIChatBot/ChatLayout";
import AIChatPage from "./pages/AIChatBot/AIChatPage";

const App = () => {
  const { isAppInstalled, isUserRegistered } = useStore((state) => ({
    isAppInstalled: state.isAppInstalled,
    isUserRegistered: state.isUserRegistered,
  }));

  return (
    <BrowserRouter>
      <ToastContainer />
      <div className="flex flex-col min-h-screen ">
        <main className="flex-grow bg-gray-100 ">
          <Routes>
            <Route
              path="/login"
              element={
                isAppInstalled && !isUserRegistered ? (
                  <Login />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/"
              element={
                !isAppInstalled ? (
                  <Home />
                ) : isUserRegistered ? (
                  <Navigate to="/user-home" />
                ) : (
                  <Navigate to="/registration-and-terms" />
                )
              }
            />

            <Route
              path="/registration-and-terms"
              element={
                !isAppInstalled ? (
                  <Navigate to="/" />
                ) : isUserRegistered ? (
                  <Navigate to="/user-home" />
                ) : (
                  <RegistrationAndTerms />
                )
              }
            />
            <Route
              path="/user-home"
              element={
                <PrivateRoute>
                  <UserHome />
                </PrivateRoute>
              }
            />
            <Route
              path="/user-profile"
              element={
                <PrivateRoute>
                  <UserProfile />
                </PrivateRoute>
              }
            />
            <Route
              path="/user-notification"
              element={
                <PrivateRoute>
                  <UserNotification />
                </PrivateRoute>
              }
            />
            <Route
              path="/user-notification/:id"
              element={
                <PrivateRoute>
                  <ViewUserNotif />
                </PrivateRoute>
              }
            />
            <Route
              path="/ai-chats/*"
              element={
                <PrivateRoute>
                  <ChatLayout />
                </PrivateRoute>
              }
            >
              {/* Nested routes within ChatLayout */}
              <Route index element={<AIChats />} />
              <Route path="chat/:id" element={<AIChatPage />} />
            </Route>

            <Route
              path="/user-contact"
              element={
                <PrivateRoute>
                  <UserContact />
                </PrivateRoute>
              }
            />
            <Route
              path="/user-location"
              element={
                <PrivateRoute>
                  <UserLocation />
                </PrivateRoute>
              }
            />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </main>
      </div>
    </BrowserRouter>
  );
};

export default App;
