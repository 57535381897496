import React, { useState } from "react";
import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { MdLocalPolice } from "react-icons/md";
import { RiEarthquakeFill } from "react-icons/ri";
import { FaHouseFire, FaHouseFloodWater } from "react-icons/fa6";
import { FaAmbulance, FaCarCrash } from "react-icons/fa";
import useStore from "../store";
import axios from "axios";
import io from "socket.io-client";
import { ButtonLoader } from "./Loader";

const socket = io(process.env.REACT_APP_API_BASE_URL);

const UserEmergencyPopup = (props) => {
  const { currentUser } = useStore((state) => ({
    currentUser: state.currentUser,
  }));

  const {
    openEmergencyPopup,
    setOpenEmergencyPopup,
    emergencyType,
    setEmergencyTypesVisible,
    activatePing,
    setEmergencyId,
  } = props;

  const [loading, setLoading] = useState(false); // Add loading state

  const handleClosePopup = () => {
    setOpenEmergencyPopup(false);
    setEmergencyTypesVisible(false);
  };

  const handleSaveAlert = async () => {
    setLoading(true); // Start loading

    // Ensure addressLocation is not empty before accessing the first item
    if (
      !currentUser.addressLocation ||
      currentUser.addressLocation.length === 0
    ) {
      console.error("Address location is missing for the current user.");
      setLoading(false);
      return;
    }

    // Destructure addressLocation to get the first address object
    const [addressLocation] = currentUser.addressLocation;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/server/user-alert/latest-alert`,
        {
          citizenId: currentUser._id,
          profilePicture: currentUser.profilePicture,
          name: currentUser.name,
          age: currentUser.age,
          gender: currentUser.gender,
          street: addressLocation.street,
          barangay: addressLocation.barangay.name,
          city: addressLocation.city.name,
          province: addressLocation.province.name,
          latitude: addressLocation.latitude,
          longitude: addressLocation.longitude,
          phone: currentUser.phone,
          emergencyType: emergencyType,
          status: "new", // Initial status
        }
      );

      if (response.status === 200) {
        const emergencyId = response.data.emergencyId; // Assuming the emergencyId is returned in the response
        // Update emergencyId in UserHome
        setEmergencyId(emergencyId);

        // Save the emergency ID and status in localStorage
        localStorage.setItem("emergencyId", emergencyId);
        localStorage.setItem("emergencyStatus", "new");

        // Emit event to start watching emergency status in backend
        socket.emit("new-emergency-alert", {
          citizenId: currentUser._id,
          emergencyId: emergencyId,
        });

        // Activate the ping animation
        activatePing();
        handleClosePopup();
      }
    } catch (error) {
      console.error("Error saving emergency alert", error);
    } finally {
      setLoading(false); // End loading
    }
  };

  const getIcon = (emergencyType) => {
    switch (emergencyType) {
      case "Crime":
        return <MdLocalPolice size={40} className="text-red-400" />;
      case "Earthquake":
        return <RiEarthquakeFill size={40} className="text-red-400" />;
      case "Health":
        return <FaAmbulance size={40} className="text-red-400" />;
      case "Fire":
        return <FaHouseFire size={40} className="text-red-400" />;
      case "Flood":
        return <FaHouseFloodWater size={40} className="text-red-400" />;
      case "Traffic Accidents":
        return <FaCarCrash size={40} className="text-red-400" />;
      default:
        return null;
    }
  };

  const addressLocation = currentUser.addressLocation?.[0];

  const currentLocation = {
    street: addressLocation?.street || "No street available",
    barangay: addressLocation?.barangay?.name || "No barangay available",
    city: addressLocation?.city?.name || "No city available",
    province: addressLocation?.province?.name || "No province available",
    region: addressLocation?.region?.name || "No region available",
    latitude: addressLocation?.latitude || 0,
    longitude: addressLocation?.longitude || 0,
  };

  const isMobile = useMediaQuery("(max-width:600px)");

  const dynamicPopupStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    p: 2,
    width: "min(90%, 490px)",
    maxHeight: isMobile ? "95vh" : "calc(100vh - 100px)",
    overflowY: "auto",
    outline: "none",
  };

  const icon = getIcon(emergencyType);

  return (
    <div>
      <Modal open={openEmergencyPopup} onClose={handleClosePopup}>
        <Box
          className="bg-white rounded-xl border border-red-400"
          sx={dynamicPopupStyle}
          style={
            isMobile || window.innerWidth <= window.innerHeight * 2
              ? dynamicPopupStyle
              : null
          }
        >
          <div className="flex flex-col justify-center items-center px-1">
            {icon && (
              <div className="bg-red-100 rounded-full p-4 mb-4">{icon}</div>
            )}
            <div className="text-center my-2">
              Are you sure you want to report this{" "}
              <span className="font-bold">{emergencyType} Emergency</span> at
              your current location in{" "}
              <span className="font-bold">
                {currentLocation.street}, {currentLocation.barangay},
                {currentLocation.city}
              </span>
              ?
            </div>

            <div className="flex w-full gap-4 mt-8">
              <button
                onClick={handleClosePopup}
                className="p-2 bg-gray-100 rounded-md w-full text-slate-500"
              >
                Cancel
              </button>
              <button
                onClick={handleSaveAlert} // Changed to handleSaveAlert
                className={`p-2 text-white rounded-md w-full ${
                  loading ? "bg-red-400 opacity-60" : "bg-red-400"
                }`}
                disabled={loading}
              >
                {loading ? <ButtonLoader color="white" /> : "Yes"}
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default UserEmergencyPopup;
