import React, { useState } from "react";
import { FaAmbulance } from "react-icons/fa";
import { FaHouseFire } from "react-icons/fa6";
import { FaHouseFloodWater } from "react-icons/fa6";
import { RiEarthquakeFill } from "react-icons/ri";
import { MdLocalPolice, MdPersonAddAlt1 } from "react-icons/md";
import { FaCarCrash } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import { IoIosSearch } from "react-icons/io";
import AddContactsPopup from "../components/UserContact/AddContactsPopup";
import DeleteContactPopup from "../components/UserContact/DeleteContactPopup";
import CallContactPopup from "../components/UserContact/CallContactPopup";
import { HiOutlineDotsVertical } from "react-icons/hi";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import profile from "../assets/defaultProfile.jfif";

function UserContact() {
  const [openAddContactPopup, setOpenAddContactPopup] = useState(false);
  const [openContactDeletePopup, setOpenContactDeletePopup] = useState(false);
  const [openCallContactPopup, setOpenCallContactPopup] = useState(false);
  const [openDialpadPopup, setOpenDialpadPopup] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [contacts, setContacts] = useState([
    {
      id: 1,
      name: "Juan Dela Cruz",
      phone: "09365125412",
      category: "Closed Contacts",
    },
    {
      id: 2,
      name: "Pedro Santiago",
      phone: "09365125",
      category: "Closed Contacts",
    },
    {
      id: 3,
      name: "Control Base / MDRRMO",
      phone: "09070366369",
      category: "Emergency Hotlines",
    },
    {
      id: 4,
      name: "Emergency Fire",
      phone: "09425632145",
      category: "Emergency Hotlines",
    },
    {
      id: 5,
      name: "Health Office",
      phone: "09853621454",
      category: "Emergency Hotlines",
    },
    {
      id: 6,
      name: "Municipal PNP",
      phone: "09548741236",
      category: "Emergency Hotlines",
    },
    {
      id: 7,
      name: "Brgy. San Isidro Main",
      phone: "09745236589",
      category: "Emergency Hotlines",
    },
    {
      id: 8,
      name: "Brgy. San Isidro Annex A",
      phone: "09421563264",
      category: "Emergency Hotlines",
    },
    {
      id: 9,
      name: "Brgy. San Isidro Annex B",
      phone: "09425698541",
      category: "Emergency Hotlines",
    },
    {
      id: 10,
      name: "Brgy. San Isidro Annex C",
      phone: "091452365239",
      category: "Emergency Hotlines",
    },
  ]);

  const filteredContacts = contacts.filter(
    (contact) =>
      contact.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      contact.phone.includes(searchQuery)
  );

  const groupedContacts = filteredContacts.reduce((acc, contact) => {
    if (!acc[contact.category]) {
      acc[contact.category] = [];
    }
    acc[contact.category].push(contact);
    return acc;
  }, {});

  const [opendot, setOpendot] = useState(null);
  const [selectedPhone, setSelectedPhone] = useState("");
  const [selectedContactId, setSelectedContactId] = useState(null);

  const open = Boolean(opendot);

  const handleOpenDialpad = (event) => {
    event.stopPropagation();
    setOpenDialpadPopup(true);
  };

  const handleAddContact = () => {
    setOpenAddContactPopup(true);
  };

  const handleDeleteContact = () => {
    setOpenContactDeletePopup(true);
  };

  const handleCallContact = (phone) => {
    setSelectedPhone(phone);
    setOpenCallContactPopup(true);
  };

  const handleOpenDotMenu = (event, contactId) => {
    event.stopPropagation();
    setSelectedContactId(contactId);
    setOpendot(event.currentTarget);
  };

  const handleCloseDotMenu = () => {
    setOpendot(null);
    setSelectedContactId(null);
  };

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="w-full">
      <div className="w-full max-w-lg fixed top-0 z-10 bg-white drop-shadow-sm">
        <div className="flex justify-between items-center px-4 py-4">
          <div className="flex font-semibold gap-4">
            <IoArrowBack
              size={20}
              onClick={handleGoBack}
              className="self-center cursor-pointer"
            />
            Contacts
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center mt-16">
        <div className="relative flex items-center w-full mx-3">
          <input
            className="w-full pl-10 pr-4 py-2 border rounded-ss-3xl rounded-ee-3xl rounded-se-3xl rounded-es-3xl focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="mySearch"
            type="text"
            placeholder="Search contacts"
            value={searchQuery}
            onChange={handleInputChange}
          />
          <div className="absolute left-3 text-gray-500">
            <IoIosSearch size={20} />
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-2 mb-4">
        <button
          onClick={handleAddContact}
          className="flex items-center gap-2 p-2"
        >
          <MdPersonAddAlt1 className="text-slate-500" size={25} />
          <span className="text-slate-500">Create new contact</span>
        </button>
      </div>
      <div className="w-full rounded-lg px-4 pb-6">
        {Object.keys(groupedContacts).map((category) => (
          <div key={category}>
            <div className="flex items-center space-x-2 mt-2 ml-5 text-sm text-slate-400">
              <div>{category}</div>
            </div>
            {groupedContacts[category].map((contact) => (
              <div
                key={contact.id}
                className="w-full rounded-2xl bg-white p-3.5 flex mt-3 items-center"
              >
                <div
                  onClick={() => handleCallContact(contact.phone)}
                  className="w-full gap-2"
                >
                  <div className="flex justify-between">
                    <div className="flex gap-2">
                      <img src={profile} className="w-10 h-10 rounded-full" />
                      <div>
                        <div className="text-start font-semibold">
                          {contact.name}
                        </div>
                        <div className="text-start text-sm text-slate-500">
                          {contact.phone}
                        </div>
                      </div>
                    </div>
                    {/* Show the dots menu only for contacts with ID 1 and 2 */}
                    {(contact.id === 1 || contact.id === 2) && (
                      <div className="flex gap-2">
                        <div className="self-center">
                          <div className="flex gap-2">
                            <div className="self-center"></div>
                            <div className="self-center">
                              <HiOutlineDotsVertical
                                size={20}
                                onClick={handleOpenDotMenu}
                                aria-controls={open ? "details" : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                className="self-center cursor-pointer"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <AddContactsPopup
        openAddContactPopup={openAddContactPopup}
        setOpenAddContactPopup={setOpenAddContactPopup}
      />
      <DeleteContactPopup
        openContactDeletePopup={openContactDeletePopup}
        setOpenContactDeletePopup={setOpenContactDeletePopup}
      />
      <CallContactPopup
        openCallContactPopup={openCallContactPopup}
        setOpenCallContactPopup={setOpenCallContactPopup}
        phone={selectedPhone}
      />
      <Menu
        anchorEl={opendot}
        id="details"
        open={Boolean(opendot)}
        onClose={handleCloseDotMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={() => {
            handleOpenDialpad();
            handleCloseDotMenu();
          }}
        >
          Pin
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleDeleteContact();
            handleCloseDotMenu();
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleDeleteContact();
            handleCloseDotMenu();
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
}

export default UserContact;
